import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Checkbox } from '@material-ui/core';


const headRowStyle = {
    backgroundColor: '#3f51b5'
}

const rejectedHeadRowStyle = {
    backgroundColor: '#b2102f'
}

const styles = {
    active : {
        color: '#FFF !important'
    },
    root: {
        color: '#FFF !important'
    }
}

class SortTableHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const { order, orderBy, rows, classes, quoteType, selectAll, handleSelectAllClick } = this.props;
        return (
            <TableHead>
                <TableRow style={quoteType === 'rejected' ? rejectedHeadRowStyle : headRowStyle}>
                    {rows.map(row => {
                        return (
                            <TableCell
                                key={row.id}
                                numeric={row.numeric}
                                padding={row.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === row.id ? order : false}
                                style={row.customStyle ? row.customStyle : null}
                            >
                            {row.id == 'select_all' &&
                            <Checkbox
                                checked={selectAll}
                                onChange={event => handleSelectAllClick(event, 'selectAll')}
                                value="selectAll"
                                >
                                </Checkbox>}
                                {row.noSorting &&
                                    <div className={classNames(classes.root, classes.active)}>
                                    {row.label}
                                    </div>}
                                {!row.noSorting &&
                                <Tooltip
                                    title="Sort"
                                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                        className={classNames(classes.root, classes.active)}
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>}
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

SortTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
};

export default withStyles(styles)(SortTableHead);