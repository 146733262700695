import React from 'react';
import TripPlannerTable from './trip_planner_table';
import TripFeedbackForm from './trip_feedback_form';
import { withStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import EnvConfig from '../../config/config';
import sortingHelper from '../../utils/sortingHelper'
import LinearProgress from '@material-ui/core/LinearProgress';

const cookies = new Cookies();
const dataRefreshIntervalSeconds = 30;

const styles = theme => ({

});

const feedbackFirstOptions = [
  { value: 'changeStartTerminal', label: 'Change Start Terminal' },
  { value: 'changeEndTerminal', label: 'Change End Terminal' },
  { value: 'changePriorMovement', label: 'Change link from prior Movement' },
  { value: 'changeNextMovement', label: 'Change link to next Movement' },
  { value: 'forceSubout', label: 'Force subout' }
]

class TripPlannerView extends React.Component {
  constructor(props) {
    
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      tours: [],
      terminals: [],
      terminalOptions : [],
      movementOptions : [],
      charterOptions : [],
      showFeedbackForm: false,
      movementSelected: null,
      feedbackTour: null,
      engineIsRunning: false,
      previousFeedbacksForMovement: [],
      hasDataErrors: false,
      user: null
    };
  }
  isValidVehicleId (idValue)  {
    return (idValue!=null && idValue!=0 && idValue!="");
  }
  
  componentDidMount() {
    
    this.interval = setInterval(() => {
      this.refreshData();
    }, 1000 * dataRefreshIntervalSeconds);

    const getAuthorization = {
      method: 'GET',
      headers: {
        Authorization: 'Token ' + cookies.get('token')
      }
    };
    
    Promise.all([
      fetch(EnvConfig.getConfig().apiUrl + "/api/base_terminals/", getAuthorization),
      fetch(EnvConfig.getConfig().apiUrl + "/api/errors/?type=private_hire&type=contract", getAuthorization),
      fetch(EnvConfig.getConfig().apiUrl + "/api/users/current/", getAuthorization),
    ])
    .then(function(responseArray){
      const unauthorized = responseArray.find(function (response) {
        return response.ok === false && response.status === 401;
      });
      if(unauthorized !== undefined){
        window.location = "/login";
      }       
      return responseArray;
    })
    .then(([
      terminalsResponse,
      hasDataErrors,
      userResponse,
    ]) => Promise.all([
      terminalsResponse.json(),
      hasDataErrors.json(),
      userResponse.json(),
    ]))
    .then(([terminalsData, hasDataErrors, userData]) => {
      const terminalOptions = terminalsData.results.filter(function(item){
        return item.base_name !== 'OPTIMAL';
      }).map(function(item){
          return { 'label' : item.base_name, 'value' : item.base_serial_no }
      });
      
      terminalOptions.push({'label': 'SUBOUT', 'value': -1})

      hasDataErrors.results.hasDataErrors = false;
      if (hasDataErrors.results.length > 0){
        hasDataErrors.results.hasDataErrors = true;
      }

      this.setState({
        terminalOptions : terminalOptions,
        hasDataErrors: hasDataErrors.results.hasDataErrors,
        user: userData,
        terminals : terminalsData.results
      });

      this.refreshData();
    },
    (error) => {
      console.log(error);
    })
    .catch(e => {
        console.log(e);
    });
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }
  
  refreshData = () => {
      const getAuthorization = {
        method: 'GET',
        headers: {
          Authorization: 'Token ' + cookies.get('token')
        }
      };

      const toursUrlPath = this.props.match.params.run_id === undefined ? '?active' : '?run_id='+ this.props.match.params.run_id;

      Promise.all([
        fetch(EnvConfig.getConfig().apiUrl + "/api/tours/" + toursUrlPath, getAuthorization),
        fetch(EnvConfig.getConfig().apiUrl + "/api/runs/recent/", getAuthorization),
      ])
        .then(function(responseArray){
          const unauthorized = responseArray.find(function (response) {
            return response.ok === false && response.status === 401;
          });
          if(unauthorized !== undefined){
            window.location = "/login";
          }       
          return responseArray;
        })
        .then(([
          toursResponse,
          recentRunResponse,
        ]) => Promise.all([
          toursResponse.json(),
          recentRunResponse.json()
        ]))
        .then(([toursData, recentRunData]) => {
          
          var movementOptions = [];
          var movementSeen = {};
          var charterOptions = [];
          var charterSeen = {}; 

          toursData.results.forEach(t => {
              t.tour_id = parseInt(t.tour_id);
            
              if(t.start_terminal === null){
                t.start_terminal = {
                  base_name : 'SUBOUT',
                  base_serial_no: -1
                }
              }

              if(t.end_terminal === null){
                t.end_terminal = {
                  base_name : 'SUBOUT',
                  base_serial_no: -1
                }
              }
              //status allocation status - default is not mismatched
              t.allocationMismatch = false;
              t.totalPrice = 0;

              t.movements = t.tour_movements.map(tm => {
                if(tm.movement != null){
                  
                  if(tm.movement.movement_type === 'contract' && tm.contract_date != null && tm.contract_date.vehicle != null){
                    var vehicles = JSON.parse(tm.contract_date.vehicle.replace(/None/g,0).replace(/'/g, '"'));
                    for(var i=0;i<vehicles.length;i++){
                      if(tm.engine_movement_id.includes('v-'+vehicles[i].id)){
                        if(vehicles[i].vehicle_id != undefined){
                          tm.movement.allocated_vehicle_id = vehicles[i].vehicle_id;
                        }
                      }
                    }
                  }
                  if(tm.movement.movement_type === 'contract' && tm.contract_date != null && tm.movement.vehicle != null){
                    var vehicles = JSON.parse(tm.movement.vehicle.replace(/None/g,0).replace(/'/g, '"'));
                    for(var i=0;i<vehicles.length;i++){
                      tm.movement.vehicle_price = vehicles[i].price;
                    }
                  }
                  else if (tm.movement.movement_type !== 'contract' && tm.movement.vehicle != null){
                    var vehicles = JSON.parse(tm.movement.vehicle.replace(/None/g,0).replace(/'/g, '"'));
                    for(var i=0;i<vehicles.length;i++){
                      tm.movement.vehicle_price = vehicles[i].price;
                      if(tm.engine_movement_id.includes('v-'+vehicles[i].id)){
                        if(vehicles[i].vehicle_id != undefined){
                          tm.movement.allocated_vehicle_id = vehicles[i].vehicle_id;
                        }
                      }
                    }
                  }
                  
                  // booked base
                  tm.movement.booked_base = null;
                  if(tm.movement.charter.base != null){
                    const base = this.state.terminals.find(function(t){
                        return tm.movement.charter.base == t.base_serial_no;
                    });

                    tm.movement.booked_base = base !== undefined && base !== null ? base.base_name : 'OPTIMAL';
                  }


                  
                  // get dates
                  if(tm.movement.movement_type === 'contract' && tm.contract_date != null){
                    tm.movement.spot_datetime = tm.contract_date.spot_datetime;
                    tm.movement.arrival_datetime = tm.contract_date.arrival_datetime;
                    tm.movement.leave_datetime = tm.contract_date.leave_datetime;
                    tm.movement.back_datetime = tm.contract_date.back_datetime;
                  }
                  else if(tm.movement.movement_type === 'contract'){
                    tm.movement.spot_datetime = '';
                    tm.movement.arrival_datetime = '';
                    tm.movement.leave_datetime = '';
                    tm.movement.back_datetime = '';
                  }

                  tm.movement.is_round_trip = !tm.movement.single_journey && tm.movement.vehicle_to_stay;
                  
                  tm.movement.engine_movement_id = tm.engine_movement_id;

                  // required driver hours for this movement
                  tm.movement.day_1_road_hours = tm.day_1_road_hours != null ? tm.day_1_road_hours.toFixed(2) : 'N/A';
                  tm.movement.day_1_duty_hours = tm.day_1_duty_hours != null ? tm.day_1_duty_hours.toFixed(2) : 'N/A';
                  tm.movement.total_duty_hours = tm.total_duty_hours != null ? tm.total_duty_hours.toFixed(2) : 'N/A';
                  tm.movement.fresh_driver_swap = tm.fresh_driver_swap;

                  // add unique movements to options array
                  if(!movementSeen.hasOwnProperty(tm.movement.id)){
                    movementOptions.push({'label' : tm.movement.movement_id, 'value' : tm.movement.engine_movement_id});
                    movementSeen[tm.movement.id] = true;
                  }

                  // add unique charters to options array
                  if(!charterSeen.hasOwnProperty(tm.movement.charter.charter_id)){
                    charterOptions.push({'label' : tm.movement.charter.charter_id, 'value' : tm.movement.charter.charter_id});
                    charterSeen[tm.movement.charter.charter_id] = true;
                  }

                  //vehicle allocation mismatch
                  if(this.isValidVehicleId(t.vehicle_id) && this.isValidVehicleId(tm.movement.allocated_vehicle_id)){
                    if(tm.movement.allocated_vehicle_id != t.vehicle_id){
                      t.allocationMismatch = true;
                    } 
                  }

                  //add price aggregate to tour object
                  var movementPrice = parseFloat(tm.movement.vehicle_price);
                  if (movementPrice!=null && !isNaN(movementPrice)){
                    t.totalPrice += movementPrice;
                  }

                  tm.movement.tour_id = t.id;
                }
                
                return tm.movement;
              });
    
              var capacity_facilities_array = t.vehicle_type.split('_');
    
              t.vehicle_capacity = capacity_facilities_array[0];
              t.facilities = '';
              if(capacity_facilities_array.length > 1){
                for(var i = 1; i < capacity_facilities_array.length; i++){
                  if(i > 1){
                    t.facilities += ',';
                  }
                  t.facilities += capacity_facilities_array[i]
                }
              }
              let basesList = ''
              if (t.movements != null && t.movements.length > 0){
                let basesArray = t.movements.filter(m => {return m != null}).map(m => { return m.booked_base } );
                var basesUnique = [...new Set(basesArray.filter(Boolean))]
                basesList = basesUnique.join(', ')
              }
              t.booked_bases = basesList;
          });
    
          var recentRunIsRunning = false;
          
          if(recentRunData.is_running){
            recentRunIsRunning = true;
          }
          else if(!recentRunData.is_running && this.state.engineIsRunning){
            recentRunIsRunning = false;
          }
    
          this.setState({
            tours: toursData.results,
            movementOptions : movementOptions,
            engineIsRunning : recentRunIsRunning,
            charterOptions : charterOptions,
            isLoaded: true,
          });

        },
        (error) => {
          console.log(error);
        })
        .catch(e => {
            console.log(e);
        });
  }
  
  
  handleShowEditButton = (event, movement) => {
    
    this.setState({movementSelected: movement});
  };

  handleShowFeedbackForm = (event, movement, tour) => {

    this.setState({showFeedbackForm: true, movementSelected : movement, feedbackTour: tour}, () => {

      fetch(EnvConfig.getConfig().apiUrl + "/api/feedbacks/?engine_movement_id=" + movement.engine_movement_id, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Token ' + cookies.get('token')
        },
      })
      .then(function(response){
        if(response.ok === false && response.status === 401){
          window.location = "/login";
        }       
        return response;
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({previousFeedbacksForMovement: result.results});
          },
          (error) => {
            console.log(error);
          }
        )  
    });
  }

  handleHideFeedbackForm = event => {
    this.setState({showFeedbackForm: false});
  }

  handleSubmitFeedbackClick = (event, movement) => {
    this.setState({showFeedbackForm: false});
  }

  filterFeedbackFirstOptions = (options) => {
    var isFirstMovement = false;
    var isLastMovement = false;

    if(this.state.feedbackTour != null && this.state.feedbackTour.movements != null && this.state.feedbackTour.movements.length > 0){

      var sortedMovements = sortingHelper.stableSort(this.state.feedbackTour.movements, sortingHelper.getSorting('asc', 'spot_datetime'));

      var index = 0;
      if(sortedMovements.length > 1){
          for(var i=0; i < sortedMovements.length;i++){
              if(sortedMovements[i].engine_movement_id == this.state.movementSelected.engine_movement_id){
                  index = i;
                  break;
              }
          }
      }
      
      if(index === 0){
        isFirstMovement = true;
      }

      if((index + 1) == this.state.feedbackTour.movements.length){
        isLastMovement = true;
      }
    }

    const hasAlterLinkPrior = this.state.previousFeedbacksForMovement.find(f => {
      return f.code === 'AlterLinkSelectedPriorNoTrips' || f.code === 'AlterLinkSelectedDisallowPrior' || f.code === 'AlterLinkSelectedForceLinkPrior';
    });

    const hasAlterLinkAfter = this.state.previousFeedbacksForMovement.find(f => {
      return f.code === 'AlterLinkSelectedNoTripsAfter' || f.code === 'AlterLinkSelectedDisallowAfter' || f.code === 'AlterLinkSelectedForceLinkAfter';
    });

    const newOptions = options.filter(option => {
        if(option.value === 'changePriorMovement' && (isFirstMovement || hasAlterLinkPrior != null)){
          return false;
        }
        else if(option.value === 'changeNextMovement' && (isLastMovement || hasAlterLinkAfter != null)){
          return false;
        }
        else if(option.value === 'changeStartTerminal' && (!isFirstMovement || hasAlterLinkPrior)){
          return false;
        }
        else if(option.value === 'changeEndTerminal' && (!isLastMovement || hasAlterLinkAfter)){
          return false;
        }
        else{
          return true;
        }
    });
    
    return newOptions;
  }

  render() {
    const { error, isLoaded, tours, showFeedbackForm, movementSelected, terminalOptions, movementOptions, engineIsRunning, previousFeedbacksForMovement, charterOptions, hasDataErrors, feedbackTour, user} = this.state;
    const { handleShowFeedbackForm, handleHideFeedbackForm, handleSubmitFeedbackClick } = this;
    const finalFirstOptions = this.filterFeedbackFirstOptions(feedbackFirstOptions)
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <LinearProgress />;
    } else {
      return (
        <div>
          <TripPlannerTable tours={tours} handleShowFeedbackForm={handleShowFeedbackForm} toolbarTitle='Trips/Tours' editMode={this.props.match.params.run_id === undefined} engineIsRunning={engineIsRunning} movementOptions={movementOptions} charterOptions={charterOptions} hasDataErrors={hasDataErrors} startTerminalOptions={terminalOptions} user={user}/>
          <TripFeedbackForm handleSubmitFeedbackClick={handleSubmitFeedbackClick} movementSelected={movementSelected} showFeedbackForm={showFeedbackForm} handleHideFeedbackForm={handleHideFeedbackForm} terminalOptions={terminalOptions} movementOptions={movementOptions}  previousFeedbacksForMovement={previousFeedbacksForMovement} feedbackTour={feedbackTour} firstOptions={finalFirstOptions}/>
        </div>
      );
    }
  }
}

export default withStyles(styles)(TripPlannerView);