import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DoneIcon from '@material-ui/icons/Done';
import ExitIcon from '@material-ui/icons/ExitToApp';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AccountBox from '@material-ui/icons/AccountBox';
import { Link } from 'react-router-dom'
import MenuItem from '@material-ui/core/MenuItem';
import LogoImg from '../RR-Logo.png'
import EnvConfig from '../config/config';
import Cookies from 'universal-cookie';
import Moment from 'moment';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const cookies = new Cookies();

const drawerWidth = 240;

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    zIndex: 1,
    // overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
  },
  appBar: {
    position: 'absolute',
    marginLeft: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    backgroundColor: '#2c387e'
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
  },
});

const dataRefreshIntervalSeconds = 30;

class DefaultDashboard extends React.Component {
  state = {
    mobileOpen: false,
    user: null,
    vehicleTerminalCounts: [],
    terminals : []
  };

  componentDidMount() {

    this.interval = setInterval(() => {
      this.refreshData();
    }, 1000 * dataRefreshIntervalSeconds);

    const getAuthorization = {
      method: 'GET',
      headers: {
        Authorization: 'Token ' + cookies.get('token')
      }
    };

    Promise.all([
      fetch(EnvConfig.getConfig().apiUrl + "/api/base_terminals/", getAuthorization),
      fetch(EnvConfig.getConfig().apiUrl + "/api/users/current/", getAuthorization),
    ])
    .then(function(responseArray){
      const unauthorized = responseArray.find(function (response) {
        return response.ok === false && response.status === 401;
      });
      if(unauthorized !== undefined){
        window.location = "/login";
      }       
      return responseArray;
    })
    .then(([
      terminalsResponse,
      userResponse,
    ]) => Promise.all([
      terminalsResponse.json(),
      userResponse.json(),
    ]))
    .then(([terminalsData, userData]) => {

      this.setState({
        user: userData,
        terminals : terminalsData.results
      });

      this.refreshData();
    },
    (error) => {
      console.log(error);
    })
    .catch(e => {
        console.log(e);
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  refreshData = () =>{
    if (window.location.pathname === '/trip-planner' ){
      const getAuthorization = {
        method: 'GET',
        headers: {
          Authorization: 'Token ' + cookies.get('token')
        }
      };

      Promise.all([
        fetch(EnvConfig.getConfig().apiUrl + "/api/vehicle_terminal_counts/", getAuthorization),
      ])
      .then(function(responseArray){
        const unauthorized = responseArray.find(function (response) {
          return response.ok === false && response.status === 401;
        });
        if(unauthorized !== undefined){
          window.location = "/login";
        }       
        return responseArray;
      })
      .then(([
        terminalCountsResponse,
      ]) => Promise.all([
        terminalCountsResponse.json(), 
      ]))
      .then(([terminalCountsData]) => {

        var terminalCounts = {}
        
        terminalCountsData.results.forEach(terminalCount => {

          const terminal = this.state.terminals.find(t => {
              return t.id == terminalCount.terminal;
          });

          const terminalName = terminal.base_name;

          if(!(terminalName in terminalCounts)){
            terminalCounts[terminalName] = {}
          }

          var countDate = Moment(terminalCount.count_date, 'MM-DD-YYYY HH:mm:ss');

          if(!(countDate.format('MM/DD/YYYY') in terminalCounts[terminalName])){
            terminalCounts[terminalName][countDate.format('MM/DD/YYYY')] = [];
          }

          var cap = {}
          cap['cap'] = terminalCount.seats;
          cap['total'] = terminalCount.total_buses;
          cap['available'] = terminalCount.buses_available;

          terminalCounts[terminalName][countDate.format('MM/DD/YYYY')].push(cap);
        });
      
        this.setState({
          vehicleTerminalCounts: terminalCounts
        });
      },
      (error) => {
        console.log(error);
      })
      .catch(e => {
          console.log(e);
      });
    }
  }


  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  render() {
    const { user, vehicleTerminalCounts } = this.state;
    const { classes, theme } = this.props;
    const { pathname } = window.location;
    const drawer = (
      <div>
        {/* <div className={classes.toolbar} /> */}
        <List>
          <div>
            <ListItem button>
              <ListItemIcon>
                <img src={LogoImg} alt='Roadrunner logo'/>
              </ListItemIcon>
            </ListItem>
          </div>
        </List>
        <Divider />
        <List>
          <div>
            { user != null && (user.is_superuser || user.groups.find(g => { return g.name == 'sales'}) != undefined) &&
            <MenuItem button component={Link} selected={pathname === '/quotes'} to="/quotes" >
              <ListItemIcon>
                <ReceiptIcon />
              </ListItemIcon>
              <ListItemText primary="Quotes" />
            </MenuItem>}
            { user != null && (user.is_superuser || user.groups.find(g => { return g.name == 'dispatcher'}) != undefined) &&
            <MenuItem button component={Link} selected={pathname === '/trip-planner'} to="/trip-planner">
              <ListItemIcon>
                <DoneIcon />
              </ListItemIcon>
              <ListItemText primary="Trip Planner" />
            </MenuItem>}
            { user != null && user.is_staff &&
            <a href={EnvConfig.getConfig().adminLink} target="_blank" rel="noopener noreferrer">
              <MenuItem button>
                <ListItemIcon>
                  <AccountBox />
                </ListItemIcon>
                <ListItemText primary="Admin" />
              </MenuItem>
            </a>}
          </div>
        </List>
        <Divider />
        <List>
          <div>
            <MenuItem button component={Link} to="/login">
              <ListItemIcon>
                <ExitIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </MenuItem>
          </div>
        </List>
        <Divider />
        {pathname === '/trip-planner' && user != null && user.is_superuser && Object.keys(vehicleTerminalCounts).length > 0 &&
        <React.Fragment>
          <Typography variant="subheading" color="inherit" style={{'padding' : '10px 10px'}}>
            Terminal Capacity
          </Typography>
          {Object.keys(vehicleTerminalCounts).map(baseKey => {
            return <ExpansionPanel key={baseKey}>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        {baseKey}
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails style={{'padding' : '3px 5px 5px'}}>
                      <List style={{'width' : '100%'}}>
                        {
                          Object.keys(vehicleTerminalCounts[baseKey]).map(baseDate => {
                            return <ExpansionPanel key={baseDate}>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                      {baseDate}
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails style={{'padding' : '3px 5px 5px'}}>
                                      <Table>
                                        <TableHead>
                                          <TableRow>
                                            <TableCell style={{ padding: '4px 5px' }}>Size</TableCell>
                                            <TableCell style={{ padding: '4px 5px' }}>Total</TableCell>
                                            <TableCell style={{ padding: '4px 5px' }}>Available</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {
                                          vehicleTerminalCounts[baseKey][baseDate].map(cap => {
                                            return (
                                              <TableRow
                                              key={cap.cap}>
                                                <TableCell style={{ padding: '4px 5px' }}>{cap.cap} </TableCell>
                                                <TableCell style={{ padding: '4px 5px' }}>{cap.total} </TableCell>
                                                <TableCell style={{ padding: '4px 5px' }}>{cap.available} </TableCell>
                                              </TableRow>)
                                        })}
                                        </TableBody>
                                      </Table>
                                    </ExpansionPanelDetails>
                                  </ExpansionPanel>
                        })}
                      </List>
                      </ExpansionPanelDetails>
                  </ExpansionPanel>
          })
          }
        </React.Fragment>}
      </div>
    );

    return (
      <div className={classes.root}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.navIconHide}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="title" color="inherit" noWrap>
              SMART-Charter
            </Typography>
          </Toolbar>
        </AppBar>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={this.state.mobileOpen}
            onClose={this.handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            variant="permanent"
            open
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div>{this.props.children}</div>
        </main>
      </div>
    );
  }
}

DefaultDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(DefaultDashboard);