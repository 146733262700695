import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import sortingHelper from '../../utils/sortingHelper';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Chip from '@material-ui/core/Chip';
import ErrorIcon from '@material-ui/icons/Error';
import Avatar from '@material-ui/core/Avatar';
import ReactMoment from 'react-moment';

const rows = [
  { id: 'movement_id', numeric: false, label: 'Movement ID' },
  { id: 'error_datetime', numeric: false, label: 'Date Created' },
  { id: 'trip_datetime', numeric: false, label: 'Trip Date'},
  { id: 'charter_type', numeric: false, label: 'Trip Type'},
  { id: 'operator_id', numeric: false, label: 'Operator Name'},
  { id: 'errors', numeric: false, label: 'Error Messages' }
];

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: 'rgb(255, 0, 80)',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function chipsArray(errorMessages) {
  var stripedErrors = errorMessages.replace('[','').replace(']','');
  stripedErrors = stripedErrors.replace(/"/g,'');
  var messagesArray = stripedErrors.split(', ');
  return messagesArray
  
}

function cleanString(charterType) {
  if (charterType != null){
    var clean = charterType.replace('_', ' ').toLowerCase()
    clean = clean.charAt(0).toUpperCase() + clean.slice(1)
    return clean
  } else {
    return charterType
  }
}

class EnhancedTableHead extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      errorLabel: this.props.errorLabel,
    };
  }

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, rowCount, errorLabel } = this.props;
    return (
      <TableHead>
        <TableRow>
          <CustomTableCell
                  key='error_id'
                  numeric={false}
                  padding='default'
                  sortDirection={orderBy === 'error_id' ? order : false}
                >
                  <Tooltip
                    title="Sort"
                    placement={false ? 'bottom-end' : 'bottom-start'}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === 'error_id'}
                      direction={order}
                      onClick={this.createSortHandler('error_id')}
                    >
                      {errorLabel}
                    </TableSortLabel>
                  </Tooltip>
                </CustomTableCell>
          {rows.map(row => {
            return (
              
              <CustomTableCell
                key={row.id}
                numeric={row.numeric}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </CustomTableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 20%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let ErrorListToolbar = props => {
  const { classes } = props;

  return (
    <Toolbar
      className={classNames(classes.root)}
    >
      <div className={classes.title}>
        <Typography variant="title" id="tableTitle">
          Errors
        </Typography>
      </div>
      <div className={classes.spacer} />
    </Toolbar>
  );
};

ErrorListToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

ErrorListToolbar = withStyles(toolbarStyles)(ErrorListToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  tableWrapper: {
    overflowX: 'auto',
  },

  table: {
    tableLayout: 'fixed',
    // width: 'auto',
    minWidth: 700,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  chip: {
    margin: theme.spacing.unit,
    color: '#fff',
    backgroundColor: 'rgb(255, 0, 80)',
  },
  avatar: {
    color: 'rgb(255, 0, 80)',
    backgroundColor: 'rgb(156, 0, 51)',
  }
  
});

class ErrorsTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      order: 'desc',
      orderBy: 'error_datetime',
      data: this.props.errors,
      errorLabel: this.props.errorLabel,
      page: 0,
      rowsPerPage: 10,
    };
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  // handleClick = (event, id) => {
  //   const { selected } = this.state;
  //   const selectedIndex = selected.indexOf(id);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1),
  //     );
  //   }

  //   this.setState({ selected: newSelected });
  // };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const { data, order, orderBy, rowsPerPage, page, errorLabel } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    return (
      <Paper className={classes.root}>
        <ErrorListToolbar />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <colgroup>
            <col style={{width:'9%'}}></col>
            <col style={{width:'9%'}}></col>
            <col style={{width:'9%'}}></col>
            <col style={{width:'9%'}}></col>
            <col style={{width:'9%'}}></col>
            <col style={{width:'9%'}}></col>
            <col style={{width:'46%'}}></col>
            </colgroup>
            <EnhancedTableHead
              errorLabel= {errorLabel}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {sortingHelper.stableSort(data, sortingHelper.getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  return (
                    <TableRow
                      hover
                      // onClick={event => this.handleClick(event, n.id)}
                      tabIndex={-1}
                      key={n.id}
                    >
                      <CustomTableCell padding='default'>
                        {n.error_id}
                      </CustomTableCell>
                      <CustomTableCell padding='default'>
                        {n.movement_id}
                      </CustomTableCell>
                      <CustomTableCell>
                      { n.error_datetime != null && <ReactMoment parse="MM-DD-YYYY HH:mm:ss" format="MM/DD/YYYY">
                          {n.error_datetime}
                        </ReactMoment>}
                      </CustomTableCell>
                      <CustomTableCell>
                      { n.trip_datetime != null && <ReactMoment parse="MM-DD-YYYY HH:mm:ss" format="MM/DD/YYYY">
                          {n.trip_datetime}
                        </ReactMoment>}
                      </CustomTableCell>
                      <CustomTableCell>{cleanString(n.charter_type)}</CustomTableCell>
                      <CustomTableCell>{cleanString(n.operator_id)}</CustomTableCell>
                      <CustomTableCell>
                        {chipsArray(n.error_message)
                        .map(chip => {
                          return (
                            <Chip
                              avatar={
                                <Avatar className={classes.avatar}>
                                  <ErrorIcon />
                                </Avatar>
                              }
                              label={chip} 
                              className={classes.chip}
                              key={chip.replace(' ', '')}
                            />
                          )
                        })}
                        
                      </CustomTableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <CustomTableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 15, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

ErrorsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ErrorsTable);
