import Moment from 'moment';


const sortingHelper = {
    desc: (a, b, orderBy) => {
        var orderByArray = orderBy.split('.');
        var bValue = b != null ? b[orderByArray[0]] : '';
        var aValue = a != null ? a[orderByArray[0]] : '';

        if (orderByArray.length === 2) {
            bValue = '';
            aValue = '';

            if(b[orderByArray[0]] !== undefined && b[orderByArray[0]] !== null){
                bValue = b[orderByArray[0]][orderByArray[1]];
            }
            
            if(a[orderByArray[0]] !== undefined && a[orderByArray[0]] !== null){
                aValue = a[orderByArray[0]][orderByArray[1]];
            }
        }

        if(orderBy.includes("date")){
            var bMomentDate = Moment(bValue, 'MM-DD-YYYY HH:mm:ss');
            var aMomentDate = Moment(aValue, 'MM-DD-YYYY HH:mm:ss');

            if (aMomentDate.isSameOrAfter(bMomentDate)) {
                return -1;
            }
            if (bMomentDate.isSameOrAfter(aMomentDate)) {
                return 1;
            }
        }

        if (orderBy.includes("expandable_icon")){
            var bVal = b.movements.length;
            var aVal = a.movements.length;
            if (aVal >= bVal){
                return -1;
            }
            if (bVal >= aVal){
                return 1;
            }
        }
        else{
            if (bValue < aValue) {
                return -1;
            }
            if (bValue > aValue) {
                return 1;
            }
        }

        
        return 0;
    },
    stableSort: (array, cmp) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = cmp(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    },
    getSorting: (order, orderBy) => {
        return order === 'desc' ? (a, b) => sortingHelper.desc(a, b, orderBy) : (a, b) => -sortingHelper.desc(a, b, orderBy);
    }
}

export default sortingHelper;