import React from 'react';
import ErrorsTable from './errors_table';
import Cookies from 'universal-cookie';
import EnvConfig from '../../config/config';
import LinearProgress from '@material-ui/core/LinearProgress';

const cookies = new Cookies();

class ErrorsView extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        dataErrors: [],
        errorLabel: 'Quote ID'
      };
    }
  
    componentDidMount() {
      var query_param = this.props.match.params.error_type === 'quotes' ? 'quote' : this.props.match.params.error_type === 'charters' ? 'private_hire&type=contract' : 'none'
      fetch(EnvConfig.getConfig().apiUrl + "/api/errors/?type=" + query_param, {
        method: 'GET',
        headers: {
          Authorization: 'Token ' + cookies.get('token')
        },
      })
        .then(function(response){
            if(response.ok === false && response.status === 401){
            window.location = "/login";
            }       
            return response;
        })
        .then(res => res.json())
        .then(
          (result) => {
            let errorLabel = 'Quote ID'
            if (this.props.match.params.error_type == 'charters'){
              errorLabel = 'Charter ID'
            }
            result.results.forEach(function(t){
              if ( t.quote_id == null ){
                t.error_id = t.charter_id
              } else 
              {
                t.error_id = t.quote_id
              }
            })

            this.setState({
              isLoaded: true,
              dataErrors: result.results,
              errorLabel: errorLabel,

            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    }
  
    render() {
      const { error, isLoaded, dataErrors, errorLabel } = this.state;
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <LinearProgress />;
      } else {
        return (
          <ErrorsTable errors = {dataErrors} errorLabel = {errorLabel} />
        );
      }
    }
  }

  export default ErrorsView;