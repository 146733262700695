import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Moment from 'react-moment';
import Select from 'react-select';
import Cookies from 'universal-cookie';
import EnvConfig from '../../config/config';
import sortingHelper from '../../utils/sortingHelper'

const cookies = new Cookies();

const styles = theme => ({
    root: {
        display: 'flex',
        marginTop: 20,
    },
    dialog: {
        overflowY: 'visible',
    },

    formControl: {
        margin: '8px 0',
        width: '100%',
    },
    group: {
        margin: `${theme.spacing.unit}px 0`,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginTop: 0,
        width: 100,
    },

    label: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '0.850rem',
        fontWeight: 400
    },
    submit: {
        width: '20%'
    },
    card: {
        width: '48%',
        margin: '1% 1%'
    },
    fullCard: {
        width: '98%',
        margin: '1% 1%'
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

class TripFeedbackForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            feedback: 'OriginateFromDifferentTerminal',
            originTerminal: '',
            priorMovement: '',
            nextMovement: '',
            secondFeedback: '',
            showSecondFeedbackOption: false,
            secondFeedbackLabel: '',
            secondFeedbackOptions: []
        };
    }

    handleFeedbackChange = feedback => {
        var secondFeedback = '';
        var secondFeedbackLabel = '';
        var secondFeedbackOptions = [];
        var showSecondFeedbackOption = true;
        const terminalOptions = this.props.terminalOptions.slice();

        const priorMovementOptions = this.props.movementOptions.slice();

        priorMovementOptions.unshift({
            'label': 'Disallow link to prior movement',
            'value': 'DLP'
        });

        priorMovementOptions.unshift({
            'label': 'No trips prior to this movement',
            'value': 'NTP'
        });

        const nextMovementOptions = this.props.movementOptions.slice();

        nextMovementOptions.unshift({
            'label': 'Disallow link to next movement',
            'value': 'DLN'
        });

        nextMovementOptions.unshift({
            'label': 'No trips after this movement',
            'value': 'NTA'
        });

        switch (feedback.value) {
            case 'changeStartTerminal':
                secondFeedbackLabel = 'Select the new starting terminal';
                secondFeedbackOptions = terminalOptions;
                break;
            case 'changeEndTerminal':
                secondFeedbackLabel = 'Select the new ending terminal';
                secondFeedbackOptions = terminalOptions;
                break;
            case 'changePriorMovement':
                secondFeedbackLabel = 'Select prior movement option';
                secondFeedbackOptions = priorMovementOptions;
                break;
            case 'changeNextMovement':
                secondFeedbackLabel = 'Select next movement option';
                secondFeedbackOptions = nextMovementOptions;
                break;
            case 'forceSubout':
                showSecondFeedbackOption = false;
                break;
            default:
                showSecondFeedbackOption = false;
                console.log('invalid selected option');
        }
        

        this.setState({ feedback: feedback, secondFeedback: secondFeedback, secondFeedbackLabel: secondFeedbackLabel, secondFeedbackOptions: secondFeedbackOptions, showSecondFeedbackOption: showSecondFeedbackOption });

    };

    handleSecondFeedbackChange = secondFeedback => {
        this.setState({ secondFeedback: secondFeedback });
    }

    handleSubmitClick = event => {
        event.preventDefault();

        const data = this.getApiFeedbackObj();

        fetch(EnvConfig.getConfig().apiUrl + "/api/feedbacks/", {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Token ' + cookies.get('token')
            },
            body: JSON.stringify(data),
        })
            .then(function(response){
                if(response.ok === false && response.status === 401){
                window.location = "/login";
                }       
                return response;
            })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({ secondFeedback: '', feedback: 'OriginateFromDifferentTerminal', secondFeedbackLabel: '', secondFeedbackOptions: [], showSecondFeedbackOption : false}, () => {
                        this.props.handleSubmitFeedbackClick(event);
                    });
                },
                (error) => {
                    this.setState({
                        error
                    });
                }
            )
    }

    handleDialogClose = event => {
        this.setState({ secondFeedback: '', feedback: 'OriginateFromDifferentTerminal', secondFeedbackLabel: '', secondFeedbackOptions: [], showSecondFeedbackOption : false});
        this.props.handleHideFeedbackForm(event);
    };

    getApiFeedbackObj = () => {

        var code = '';
        var value = null;
        var related_value = null;

        var sortedMovements = sortingHelper.stableSort(this.props.feedbackTour.movements, sortingHelper.getSorting('asc', 'spot_datetime'))

        switch (this.state.feedback.value) {
            case 'changeStartTerminal':
                related_value = this.props.feedbackTour.start_terminal == null ? null : this.props.feedbackTour.start_terminal.id;
                if(this.state.secondFeedback.value === 'ANY'){
                    code = 'OriginateFromDifferentTerminal';
                }
                else{
                    code = 'OriginateFromTerminal';
                    value = this.state.secondFeedback.value;
                }
                break;
            case 'changeEndTerminal':
                related_value = this.props.feedbackTour.end_terminal == null ? null : this.props.feedbackTour.end_terminal.id;
                if(this.state.secondFeedback.value === 'ANY'){
                    code = 'AlterDestinationTerminalDifferent';
                }
                else{
                    code = 'AlterDestinationTerminalEndAt';
                    value = this.state.secondFeedback.value;
                }
                break;
            case 'changePriorMovement':
                if(sortedMovements.length > 1){
                    var index = 0;
                    for(var i=0; i < sortedMovements.length;i++){
                        if(sortedMovements[i].engine_movement_id == this.props.movementSelected.engine_movement_id){
                            index = i;
                            break;
                        }
                    }
                }

                if(index > 0){
                    related_value = sortedMovements[index-1].engine_movement_id;
                }

                if(this.state.secondFeedback.value === 'NTP'){
                    code = 'AlterLinkSelectedPriorNoTrips';
                }
                else if(this.state.secondFeedback.value === 'DLP'){
                    code = 'AlterLinkSelectedDisallowPrior';
                }
                else{
                    code = 'AlterLinkSelectedForceLinkPrior';
                    value = this.state.secondFeedback.value;
                }
                break;
            case 'changeNextMovement':
                if(sortedMovements.length > 1){
                    var index = 0;
                    for(var i=0; i < sortedMovements.length;i++){
                        if(sortedMovements[i].engine_movement_id == this.props.movementSelected.engine_movement_id){
                            index = i;
                            break;
                        }
                    }
                }

                if(index < (sortedMovements.length - 1)){
                    related_value = sortedMovements[index+1].engine_movement_id;
                }

                if(this.state.secondFeedback.value === 'NTA'){
                    code = 'AlterLinkSelectedNoTripsAfter';
                }
                else if(this.state.secondFeedback.value === 'DLN'){
                    code = 'AlterLinkSelectedDisallowAfter';
                }
                else{
                    code = 'AlterLinkSelectedForceLinkAfter';
                    value = this.state.secondFeedback.value;
                }
                break;
            case 'forceSubout':
                code = 'ForceSubout';
                related_value = this.props.feedbackTour.tour_id;
                break;
            default:
                console.log('invalid selected option');
        }

        const data = {
            code: code,
            value: value,
            movement: this.props.movementSelected.id,
            engine_movement_id: this.props.movementSelected.engine_movement_id,
            related_value: related_value
        };

        return data;
    }

    beautify_feedback = (feedback) => {
        // TODO: add value from second option if present
        switch(feedback.code){
            case 'OriginateFromDifferentTerminal':
                return <Typography key={feedback.id} component="p">Change Start Terminal</Typography>;
            case 'OriginateFromTerminal':
                return <Typography key={feedback.id} component="p">Change Start Terminal</Typography>;
            case 'AlterLinkSelectedPriorNoTrips':
                return <Typography key={feedback.id} component="p">No trips prior to this movement</Typography>;
            case 'AlterLinkSelectedDisallowPrior':
                return <Typography key={feedback.id} component="p">Disallow link between selected trip and prior trip</Typography>;
            case 'AlterLinkSelectedForceLinkPrior':
                return <Typography key={feedback.id} component="p">Change link to previous movement</Typography>;
            case 'AlterLinkSelectedNoTripsAfter':
                return <Typography key={feedback.id} component="p">No trips after this movement</Typography>;
            case 'AlterLinkSelectedDisallowAfter':
                return <Typography key={feedback.id} component="p">Disallow link to next movement</Typography>;
            case 'AlterLinkSelectedForceLinkAfter':
                return <Typography key={feedback.id} component="p">Change link to next Movement</Typography>;
            case 'AlterDestinationTerminalDifferent':
                return <Typography key={feedback.id} component="p">Change End Terminal</Typography>;
            case 'AlterDestinationTerminalEndAt':
                return <Typography key={feedback.id} component="p">Change End Terminal</Typography>;
            case 'ForceSubout':
                return <Typography key={feedback.id} component="p">Force subout</Typography>;
            default:
            return '';
        }
    };

    render() {
        const { feedback, secondFeedback, showSecondFeedbackOption, secondFeedbackLabel, secondFeedbackOptions } = this.state;
        const { classes, movementSelected, showFeedbackForm, previousFeedbacksForMovement, firstOptions } = this.props;
        const {beautify_feedback} = this;

        return (
            <Dialog PaperProps={{ className: classes.dialog }}
                open={showFeedbackForm}
                onClose={this.handleDialogClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogContent style={{ overflowY: 'visible' }}>
                    <div style={{ display: 'flex'}}>
                        <Card className={classes.fullCard}>
                            <CardContent>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    Charter ID: {movementSelected != null && movementSelected.charter.charter_id}
                                </Typography>
                                <Typography className={classes.pos} color="textSecondary">
                                    Movement ID: {movementSelected != null && movementSelected.movement_id}
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                    <div style={{ display: 'flex'}}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    From
                                </Typography>
                                <Typography component="p">
                                    {movementSelected != null && movementSelected.pickup_location_name}
                                </Typography>
                                <Typography className={classes.pos} color="textSecondary">
                                    Spot Date:
                                    <Moment parse="MM-DD-YYYY HH:mm:ss" format="MM/DD/YYYY HH:mm">
                                        {movementSelected != null && movementSelected.spot_datetime}
                                    </Moment>
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card className={classes.card}>
                            <CardContent>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    To
                                </Typography>
                                <Typography component="p">
                                    {movementSelected != null && movementSelected.destination_location_name}
                                </Typography>
                                <Typography className={classes.pos} color="textSecondary">
                                    Arrival Date:
                                    <Moment parse="MM-DD-YYYY HH:mm:ss" format="MM/DD/YYYY HH:mm">
                                        {movementSelected != null && movementSelected.arrival_datetime}
                                    </Moment>
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                    <div style={{ display: 'flex'}}>
                        <Card className={classes.fullCard}>
                            <CardContent>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    Previous Feedbacks:
                                </Typography>
                                {
                                    previousFeedbacksForMovement.map(feedback => {
                                        return beautify_feedback(feedback);
                                    })
                                }
                            </CardContent>
                        </Card>
                    </div>
                    <form className={classes.form} onSubmit={this.handleSubmitClick}>
                        <FormControl required className={classes.formControl}>
                            <Select 
                                value={feedback}
                                onChange={this.handleFeedbackChange}
                                name="feedback-1"
                                options={firstOptions}
                                placeholder="Options"
                                inputProps={{
                                    id: 'feedback-1',
                                }}
                            > 
                            
                            {
                                firstOptions.map(feedback => {
                                    return (
                                        <MenuItem key={feedback.value} value={feedback.value}>{feedback.label}</MenuItem>
                                )
                            })
                        }

                            </Select>
                        </FormControl>
                        {showSecondFeedbackOption &&
                            <FormControl required className={classes.formControl}>
                                <Select
                                    value={secondFeedback}
                                    onChange={this.handleSecondFeedbackChange}
                                    name="feedback-2"
                                    options={secondFeedbackOptions}
                                    placeholder={secondFeedbackLabel}
                                    inputProps={{
                                        id: 'feedback-2',
                                    }}
                                >
                                    {
                                        secondFeedbackOptions.map(secondFeedback => {
                                            return (
                                                <MenuItem key={secondFeedback.value} value={secondFeedback.value}>{secondFeedback.label}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Submit
                        </Button>
                    </form>
                </DialogContent>
            </Dialog>
        );
    }
}

TripFeedbackForm.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TripFeedbackForm);