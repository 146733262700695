import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Cookies from 'universal-cookie';
import LogoImg from '../RR-Logo.png';
import EnvConfig from '../config/config';
import FormHelperText from '@material-ui/core/FormHelperText';

const cookies = new Cookies();

const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

class Login extends React.Component {
  state = {
    error: null,
    userName: '',
    password: '',
    token: '',
  };

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  getToken = (event) => {
    event.preventDefault();
    fetch(EnvConfig.getConfig().apiUrl + "/get_auth_token/", {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: this.state.userName,
        password: this.state.password,
      }),
    })
      .then(res => res.json())
      .then(
        (result) => {
          if(result.token === undefined){
            this.setState({ error : 'Unable to log in with the provided credentials'})
          }
          else{
            this.setState({
              token: result.token, 
              error: null
            });
            cookies.set('token', result.token, { path: '/' });
            fetch(EnvConfig.getConfig().apiUrl + "/api/users/current/", {
              method: 'GET',
              headers: {
                Authorization: 'Token ' + cookies.get('token')
              },
            })
              .then(res => res.json())
              .then(
                (userResults) => {
                  cookies.set('smcuserid', userResults.id, { path: '/' });

                  if(userResults.is_superuser || userResults.groups.find(g => { return g.name == 'sales'}) != undefined){
                    window.location = "/quotes"
                  }
                  else if(userResults.groups.find(g => { return g.name == 'dispatcher'}) != undefined){
                    window.location = "/trip-planner"
                  }
                  else{
                    this.setState({
                      error : 'This user does not have a role associated with its user profile'
                    });
                  }
                },
                (error) => {
                  console.log(error);
                }
              )
              .catch(e => {
                console.log(e);
              });
          }
        },
        (error) => {
          this.setState({
            error : 'Error with login endpoint'
          });
        }
      )
  }

  render() {
    const { classes } = this.props;
    const { error } = this.state;

    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <div>
              <img src={LogoImg} alt='Roadrunner Logo'/>
            </div>
            <Typography component="h3" variant="display1">
              SMART-Charter
          </Typography>
            <Avatar className={classes.avatar}>
              <LockIcon />
            </Avatar>
            <form className={classes.form} onSubmit={this.getToken}>
              { error && 
              <FormHelperText error={true}>{error}</FormHelperText>}
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="name-simple">Username</InputLabel>
                <Input id="name-simple" name="name-simple" value={this.state.userName} onChange={this.handleChange('userName')} />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password-simple">Password</InputLabel>
                <Input
                  name="password-simple"
                  type="password"
                  id="password-simple"
                  autoComplete="current-password"
                  value={this.state.password}
                  onChange={this.handleChange('password')}
                />
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign in
            </Button>
            </form>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login);
