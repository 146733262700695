import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Helmet } from "react-helmet";
// import logo from './logo.svg';
import './css/App.css';
import QuoteView from './components/quote/quote_view';
import DefaultDashboard from './components/default_dashboard';
import LoginPage from './components/login';
import TripPlannerView from './components/planner/trip_planner_view';
import PlannerHistoryView from './components/history/planner_history_view';
import PlannerCompareView from './components/history/planner_compare_view';
import ErrorsView from './components/errors/errors_view';
// import MapView from './components/map/map_view';
import MapLayout from './components/map/map_layout';

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route {...rest} render={props => (
    <Layout>
      <Component {...props} />
    </Layout>
  )} />
);

const App = () => (
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>SMART-Charter</title>
    </Helmet>
    <Router onChange={()=> console.log('hi')}>
      <Switch>
        <AppRoute exact path="/" layout={LoginPage} component={LoginPage} />
        <AppRoute exact path="/quotes" layout={DefaultDashboard} component={QuoteView} />
        <AppRoute exact path="/:error_type/errors" layout={DefaultDashboard} component={ErrorsView} />
        <AppRoute exact path="/trip-planner" layout={DefaultDashboard} component={TripPlannerView} />
        <AppRoute exact path="/trip-planner/:run_id" layout={DefaultDashboard} component={TripPlannerView}/>
        <AppRoute exact path="/trip-planner-history" layout={DefaultDashboard} component={PlannerHistoryView} />
        <AppRoute exact path="/trip-planner-compare/:current_run_id/:previous_run_id" layout={DefaultDashboard} component={PlannerCompareView} />
        <AppRoute exact path="/dashboard" layout={DefaultDashboard} component={DefaultDashboard} />
        <AppRoute exact path="/login" layout={LoginPage} component={LoginPage} />
        {/* <AppRoute exact path="/map/:tour_id" layout={MapLayout} component={MapView} /> */}
      </Switch>
    </Router>
  </div>
);
 
export default App;