import React from 'react';
import QuoteTable from './quote_table';
import Cookies from 'universal-cookie';
import EnvConfig from '../../config/config';
import LinearProgress from '@material-ui/core/LinearProgress';


const cookies = new Cookies();
const dataRefreshIntervalSeconds = 30;

class QuoteView extends React.Component {
    constructor(props) {
      super(props);
      this.handleQuoteType = this.handleQuoteType.bind(this);
      this.state = {
        error: null,
        isLoaded: false,
        quotes: [],
        hasErrors: false,
        hasQuoteErrors: false,
        hasTripErrors: false,
        quoteType: 'pending',
        user: null,
        quoteOptions : [],
        movementOptions: []
      };
    }

    handleQuoteType = (event, quoteType) => {
      if(quoteType != null){
        this.setState({quoteType : quoteType, isLoaded : false, quotes : [] }, () =>{
          this.refreshData();
        });
      }
    }

    componentDidMount() {

      this.interval = setInterval(() => {
        this.refreshData();
      }, 1000 * dataRefreshIntervalSeconds);

      this.refreshData();
    }

    componentWillUnmount() {
      clearInterval(this.interval);
    }

    refreshData = () => {
      const getAuthorization = {
        method: 'GET',
        headers: {
          Authorization: 'Token ' + cookies.get('token')
        }
      };


      Promise.all([
        fetch(EnvConfig.getConfig().apiUrl + "/api/quotes/?status=" + this.state.quoteType, getAuthorization),
        fetch(EnvConfig.getConfig().apiUrl + "/api/errors/?type=quote", getAuthorization),
        fetch(EnvConfig.getConfig().apiUrl + "/api/errors/?type=private_hire&type=contract", getAuthorization),
        fetch(EnvConfig.getConfig().apiUrl + "/api/users/current/", getAuthorization),
      ])
      .then(function(responseArray){
        const unauthorized = responseArray.find(function (response) {
          return response.ok === false && response.status === 401;
        });
        if(unauthorized !== undefined){
          window.location = "/login";
        }       
        return responseArray;
      })
      .then(([
        quotesResponse,
        hasErrorsResponse,
        hasDataErrors,
        userResponse,
      ]) => Promise.all([
        quotesResponse.json(),
        hasErrorsResponse.json(),
        hasDataErrors.json(),
        userResponse.json(),
      ]))
      .then(([quoteData, hasErrorsData, hasTripErrors, userData]) => {
        hasErrorsData.results.hasErrors = false;
        var totalErrorCount = hasErrorsData.results.length+hasTripErrors.results.length;

        if ( totalErrorCount >0){
          hasErrorsData.results.hasErrors = true;
        }

        var activeMovements = [];
        var quoteOptions = [];
        var movementOptions = [];
        const quoteType = this.state.quoteType;
        quoteData.results.forEach(function(q){
          activeMovements = []
          let totalVehicles = 0;
          q.movements.forEach(function(m){
            if (m.status == 'active') {
              activeMovements.push(m);
              movementOptions.push({'label' : m.movement_id, 'value' : m.movement_id});
            }
            q.movements = activeMovements;
            let vehicleArray = JSON.parse(m.vehicle)
            vehicleArray.forEach(v => {
              let vehicle_q = parseInt(v["quantity"]);
              totalVehicles += vehicle_q;
            })
          });
          quoteOptions.push({'label' : q.quote_id, 'value' : q.quote_id});
          
          // vehicle count
          q.vehicle_count = totalVehicles;

          // final price
          let final_price = "";
          if (quoteType != 'pending'){
            if (quoteType == "rejected" && q.rejected_price > 0){
              final_price = Math.round(q.rejected_price);
            }
            if (quoteType =="accepted" && q.accepted_price > 0){
              final_price = Math.round(q.accepted_price);
            }
          }

          q.final_price = final_price;

        })
        this.setState({
          isLoaded: true,
          quotes: quoteData.results,
          hasErrors: hasErrorsData.results.hasErrors,
          hasQuoteErrors: (hasErrorsData.results.length>0),
          hasTripErrors: (hasTripErrors.results.length>0),
          totalErrorCount: totalErrorCount,
          user: userData,
          quoteOptions: quoteOptions,
          movementOptions: movementOptions
        });
      },
      (error) => {
        console.log(error);
      })
      .catch(e => {
          console.log(e);
      });
    }
  
    render() {
      const { error, isLoaded, quotes, hasErrors, hasQuoteErrors, hasTripErrors, totalErrorCount, user, quoteOptions, movementOptions } = this.state;
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <LinearProgress />;
      } else {
        return (
          <QuoteTable id='quote-table' quotes = {quotes} hasErrors = {hasErrors} hasQuoteErrors = {hasQuoteErrors} hasTripErrors={hasTripErrors}  quoteType={this.state.quoteType} totalErrorCount={totalErrorCount} handleQuoteType = {this.handleQuoteType} user={user} quoteOptions={quoteOptions} movementOptions={movementOptions}/>
          
        );
      }
    }
  }

  export default QuoteView;