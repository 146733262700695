import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Cookies from 'universal-cookie';
import Moment from 'react-moment';
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import CompareArrows from '@material-ui/icons/CompareArrows';
import EnvConfig from '../../config/config';
import LinearProgress from '@material-ui/core/LinearProgress';

const cookies = new Cookies();

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    spacer: {
        flex: '1 1 10%',
    },
    title: {
        flex: '0 0 auto',
    },
    button: {
        margin: theme.spacing.unit,
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    date : {
        fontWeight : 500
    }
});


class PlannerHistoryView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: true,
            currentVersion: null,
            previousVersion: null,
            runs: []
        };
    }

    componentDidMount() {

        fetch(EnvConfig.getConfig().apiUrl + "/api/runs/", {
            method: 'GET',
            headers: {
                Authorization: 'Token ' + cookies.get('token')
            },
        })
            .then(function(response){
                if(response.ok === false && response.status === 401){
                window.location = "/login";
                }       
                return response;
            })
            .then(res => res.json())
            .then(
                (result) => {
                    var currentVersion = null;
                    var previousVersion = null;
                    if(result.results !== undefined && result.results.length > 1){
                        currentVersion = result.results[0].id;
                        previousVersion = result.results[1].id;
                    }

                    this.setState({
                        isLoaded: true,
                        runs: result.results,
                        currentVersion: currentVersion,
                        previousVersion: previousVersion
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }
    render() {
        const { error, isLoaded, runs, currentVersion, previousVersion } = this.state;
        const { classes } = this.props;
        const comparePath = '/trip-planner-compare/' + currentVersion + '/' + previousVersion;

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <LinearProgress />;
        } else {
            return (
                <Paper className={classes.root}>
                    <Toolbar>
                        <div className={classes.title}>
                            <Typography variant="title" id="tableTitle">
                                Trip Planner History
                            </Typography>
                        </div>
                        <div className={classes.spacer} />
                        <div className={classes.actions}>
                            { currentVersion != null && previousVersion != null &&
                                <Button color="primary" component={Link} className={classes.button} to={comparePath}>
                                    Compare with previous version
                                </Button>
                            }
                        </div>
                    </Toolbar>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Version</TableCell>
                                <TableCell>Created</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {runs.map(row => {
                                const versionPath = row.is_latest ? "/trip-planner" : "/trip-planner/" + row.id;
                                const compareThisPath = '/trip-planner-compare/' + currentVersion + '/' + row.id;
                                return (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row" padding='none'> 
                                            <Button className={classes.button} color="primary" component={Link} to={versionPath}>
                                                {row.is_latest && 'CURRENT (v. ' + row.id + ')'}
                                                {!row.is_latest && 'v. ' + row.id}
                                            </Button>
                                        </TableCell>
                                        <TableCell>
                                            <Moment className={classes.date} parse="MM-DD-YYYY HH:mm:ss" format="MM/DD/YYYY HH:mm">
                                                {row.created_at}
                                            </Moment>
                                        </TableCell>
                                        <TableCell padding='none'>
                                            {!row.is_latest &&
                                            <Button className={classes.button} component={Link} to={compareThisPath} title='Compare with this version'>
                                                    <CompareArrows />
                                            </Button>}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </Paper>
            );
        }
    }
}

PlannerHistoryView.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PlannerHistoryView);