import React from 'react';
import Cookies from 'universal-cookie';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReactMoment from 'react-moment';
import Moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import EnvConfig from '../../config/config';
import sortingHelper from '../../utils/sortingHelper'
import { List } from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';
import LinearProgress from '@material-ui/core/LinearProgress';

const cookies = new Cookies();

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    spacer: {
        flex: '1 1 10%',
    },
    title: {
        flex: '0 0 auto',
    },
    button: {
        margin: theme.spacing.unit,
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    date: {
        fontWeight: 500
    },
    headRowStyle: {
        backgroundColor: '#3f51b5'
    },
    headCellStyle: {
        color: '#FFF !important'
    },
    newRowStyle: {
        backgroundColor: '#EBFEEE'
    },
    deletedRowStyle: {
        backgroundColor: '#FCEFF0'
    },
    movementsTable: {
        border: '1px solid rgba(224, 224, 224, 1)'
    },
});


class PlannerCompareView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            newTours: [],
            changedTours: [],
            deletedTours: []
        };
    }

    componentDidMount() {

        const getConfig = {
            method: 'GET',
            headers: {
                Authorization: 'Token ' + cookies.get('token')
            },
        };

        Promise.all([
            fetch(EnvConfig.getConfig().apiUrl + "/api/tours/?run_id=" + this.props.match.params.current_run_id, getConfig),
            fetch(EnvConfig.getConfig().apiUrl + "/api/tours/?run_id=" + this.props.match.params.previous_run_id, getConfig),
        ])
            .then(function(responseArray){
                const unauthorized = responseArray.find(function (response) {
                return response.ok === false && response.status === 401;
                });
                if(unauthorized !== undefined){
                window.location = "/login";
                }       
                return responseArray;
            })
            .then(([
                currentToursResponse,
                previousToursResponse,
            ]) => Promise.all([
                currentToursResponse.json(),
                previousToursResponse.json(),
            ]))
            .then(([currentToursData, previousToursData]) => {

                // normalize
                currentToursData.results.forEach(function (t) {
                    t.movements = t.tour_movements.map(function(tm){
                        if(tm.movement != null){
                            tm.movement.tour_id = t.id;
                            tm.movement.engine_movement_id = tm.engine_movement_id;
                            
                            if(tm.movement.movement_type === 'contract' && tm.contract_date != null){
                                tm.movement.spot_datetime = tm.contract_date.spot_datetime;
                                tm.movement.arrival_datetime = tm.contract_date.arrival_datetime;
                            }
                            else if(tm.movement.movement_type === 'contract'){
                                tm.movement.spot_datetime = '';
                                tm.movement.arrival_datetime = '';
                            }
                        }

                        return tm.movement;
                    });

                    var movementsIds = t.movements.map(function(m){return m != null ? m.movement_id : ''}).join('-');
                    var startBaseName = t.is_subout ? 'SUBOUT' : t.start_terminal.base_name;
                    var endBaseName = t.is_subout ? 'SUBOUT' : t.end_terminal.base_name;

                    t.tourUniqueId = (t.vehicle_type + startBaseName + endBaseName + movementsIds).replace(/\s/g, '');;

                });

                previousToursData.results.forEach(function (t) {
                    t.movements = t.tour_movements.map(function(tm){
                        if(tm.movement != null){
                            tm.movement.tour_id = t.id;
                            tm.movement.engine_movement_id = tm.engine_movement_id;

                            if(tm.movement.movement_type === 'contract' && tm.contract_date != null){
                                tm.movement.spot_datetime = tm.contract_date.spot_datetime;
                                tm.movement.arrival_datetime = tm.contract_date.arrival_datetime;
                            }
                            else if(tm.movement.movement_type === 'contract'){
                                tm.movement.spot_datetime = '';
                                tm.movement.arrival_datetime = '';
                            }
                        }

                        return tm.movement;
                    });

                    var movementsIds = t.movements.map(function(m){return m != null ? m.movement_id : ''}).join('-');
                    var startBaseName = t.is_subout ? 'SUBOUT' : t.start_terminal.base_name;
                    var endBaseName = t.is_subout ? 'SUBOUT' : t.end_terminal.base_name;

                    var start_datetime = t.on_tour ? 'ontour' : t.start_datetime

                    t.tourUniqueId = (t.vehicle_type + startBaseName + endBaseName + movementsIds).replace(/\s/g, '');;
                    
                });

                // remove no changed tours from runs
                var finalCurrentToursData = currentToursData.results.filter(function (t) {
                    const found = previousToursData.results.find(function (previousTour) {
                        return previousTour.tourUniqueId === t.tourUniqueId;
                    });
                    return found === undefined;
                });

                var finalPreviousToursData = previousToursData.results.filter(function (t) {
                    const found = currentToursData.results.find(function (currentTour) {
                        return currentTour.tourUniqueId === t.tourUniqueId;
                    });
                    return found === undefined;
                });

                // get previous and current movements hashset
                var previousMovementMap = new Map();
                previousToursData.results.forEach(function(previousTour){
                    previousTour.movements.forEach(function(previousTourMovement){
                        if(previousTourMovement != null){
                            previousTourMovement.tour = previousTour;
                            previousMovementMap.set(previousTourMovement.engine_movement_id, previousTourMovement);
                        }
                    });
                });

                var currentTourSet = new Set();
                currentToursData.results.forEach(function(currentTour){
                    currentTour.movements.forEach(function(currentTourMovement){
                        if(currentTourMovement != null){
                            currentTourSet.add(currentTourMovement.engine_movement_id);
                        }
                    });
                });

                // get new tours
                const newTours = finalCurrentToursData.filter(function (currentTour) {

                    const found = currentTour.movements.find(function (currentTourMovement) {
                        return currentTourMovement != null ? previousMovementMap.has(currentTourMovement.engine_movement_id) : false;
                    });

                    return found === undefined;
                });

                // get deleted tours
                const deletedTours = finalPreviousToursData.filter(function (previousTour) {

                    const found = previousTour.movements.find(function (previousTourMovement) {
                        return previousTourMovement != null ? currentTourSet.has(previousTourMovement.engine_movement_id) : false;
                    });

                    // filter out tours that already started
                    const momentDate = Moment(previousTour.start_datetime, 'MM-DD-YYYY HH:mm:ss');

                    return found === undefined && Moment().diff(momentDate, 'hours') <= 0;
                });

                // get changed movements
                var recurringTourMovements = new Array()
                finalCurrentToursData.forEach(function (currentTour) {
                    currentTour.movements.forEach(function (currentTourMovement) {
                        if(currentTourMovement != null && previousMovementMap.has(currentTourMovement.engine_movement_id)){
                            currentTourMovement.tour = currentTour;
                            currentTourMovement.previous_movement = previousMovementMap.get(currentTourMovement.engine_movement_id);
                            var vehicles = JSON.parse(currentTourMovement.vehicle.replace(/None/g,0).replace(/'/g, '"'));
                            for(var i=0;i<vehicles.length;i++){
                                if(currentTourMovement.engine_movement_id.includes('v-'+vehicles[i].id)){
                                    currentTourMovement.vehicle_index = i + 1;
                                }
                            }

                            var changed = false;

                            currentTourMovement.show_tour = false;
                            if(currentTourMovement.previous_movement.tour.tour_id != currentTourMovement.tour.tour_id){
                                changed = true;
                                currentTourMovement.show_tour = true;
                            }

                            currentTourMovement.show_start_terminal = false;
                            currentTourMovement.show_end_terminal = false;
                            if(currentTourMovement.previous_movement.tour.is_subout != currentTourMovement.tour.is_subout){
                                changed = true;
                                currentTourMovement.show_start_terminal = true;
                                currentTourMovement.show_end_terminal = true;
                            }

                            if(!currentTourMovement.previous_movement.tour.is_subout && !currentTourMovement.tour.is_subout){
                                if(currentTourMovement.previous_movement.tour.start_terminal.base_name != currentTourMovement.tour.start_terminal.base_name){
                                    changed = true;
                                    currentTourMovement.show_start_terminal = true;
                                }

                                if(currentTourMovement.previous_movement.tour.end_terminal.base_name != currentTourMovement.tour.end_terminal.base_name){
                                    changed = true;
                                    currentTourMovement.show_end_terminal = true;
                                }
                            }
                            
                            currentTourMovement.show_vehicle_type = false;
                            if(currentTourMovement.previous_movement.tour.vehicle_type != currentTourMovement.tour.vehicle_type){
                                changed = true;
                                currentTourMovement.show_vehicle_type = true
                            }

                            if(changed){
                                recurringTourMovements.push(currentTourMovement);
                            }
                        }
                    });
                });

                this.setState({
                    isLoaded: true,
                    newTours: newTours,
                    deletedTours: deletedTours,
                    recurringTourMovements: recurringTourMovements
                });
            },
            (error) => {
                console.log(error);
            }).catch(e => {
                console.log(e);
            });
    }

    render() {
        const { error, isLoaded, newTours, deletedTours, recurringTourMovements } = this.state;
        const { classes } = this.props;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <LinearProgress />;
        } else {
            return (
                <Paper className={classes.root}>
                    {newTours.length === 0 && recurringTourMovements.length === 0 && deletedTours.length === 0 &&
                        <Toolbar>
                        <div className={classes.title}>
                            <Typography variant="title" id="tableTitle">
                                No changes between runs
                            </Typography>
                        </div>
                        <div className={classes.spacer} />
                        </Toolbar>
                    }
                    { newTours.length > 0 && 
                    <Toolbar>
                        <div className={classes.title}>
                            <Typography variant="title" id="tableTitle">
                                New Tours
                            </Typography>
                        </div>
                        <div className={classes.spacer} />
                    </Toolbar>}
                    { newTours.length > 0 && 
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow className={classes.headRowStyle}>
                                <TableCell className={classes.headCellStyle} padding='default'>Vehicle ID</TableCell>
                                <TableCell className={classes.headCellStyle} padding='none'>Start Date</TableCell>
                                <TableCell className={classes.headCellStyle} padding='none'>Start Terminal Name</TableCell>
                                <TableCell className={classes.headCellStyle} padding='none'>End Date</TableCell>
                                <TableCell className={classes.headCellStyle} padding='none'>End Terminal Name</TableCell>
                                <TableCell className={classes.headCellStyle} padding='none'>Vehicle Size</TableCell>
                                <TableCell className={classes.headCellStyle} padding='none'>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {newTours.map(n => {
                                return (
                                    <React.Fragment key={n.id}>
                                        <TableRow key={n.id} className={classes.newRowStyle}>
                                            <TableCell component="th" scope="row" padding='default'>{n.vehicle_id}</TableCell>
                                            <TableCell component="th" scope="row" padding="none">
                                                <ReactMoment parse="MM-DD-YYYY HH:mm:ss" format="MM/DD/YYYY HH:mm">
                                                    {n.start_datetime}
                                                </ReactMoment>
                                            </TableCell>
                                            <TableCell component="th" scope="row" padding="none">{n.is_subout ? 'SUBOUT' : n.start_terminal.base_name}</TableCell>
                                            <TableCell component="th" scope="row" padding="none">
                                                <ReactMoment parse="MM-DD-YYYY HH:mm:ss" format="MM/DD/YYYY HH:mm">
                                                    {n.end_datetime}
                                                </ReactMoment>
                                            </TableCell>
                                            <TableCell component="th" scope="row" padding="none">{n.is_subout ? 'SUBOUT' : n.end_terminal.base_name}</TableCell>
                                            <TableCell component="th" scope="row" padding="none">{n.vehicle_type}</TableCell>
                                            <TableCell component="th" scope="row" padding="none">
                                                {n.is_locked && "Locked"}
                                                {!n.is_locked && n.is_on_hold && "On Hold"}
                                            </TableCell>
                                        </TableRow>
                                        {n.movements.length > 0 &&
                                            <TableRow className={classes.newRowStyle}>
                                                <TableCell colSpan={'100%'}>
                                                    <Table className={classes.movementsTable}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Charter ID</TableCell>
                                                                <TableCell>Movement ID</TableCell>
                                                                <TableCell>Spot Date</TableCell>
                                                                <TableCell>Pickup</TableCell>
                                                                <TableCell>Arrival Date</TableCell>
                                                                <TableCell>Destination</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                sortingHelper.stableSort(n.movements, sortingHelper.getSorting('asc', 'spot_datetime'))
                                                                .filter( m => {return m != null;})
                                                                .map(m => {
                                                                    return (
                                                                        <TableRow
                                                                            role="checkbox"
                                                                            key={m.engine_movement_id + n.tour_id}>
                                                                            <TableCell>{m.charter.charter_id}</TableCell>
                                                                            <TableCell>{m.movement_id}</TableCell>
                                                                            <TableCell>
                                                                                <ReactMoment parse="MM-DD-YYYY HH:mm:ss" format="MM/DD/YYYY HH:mm">
                                                                                    {m.spot_datetime}
                                                                                </ReactMoment>
                                                                            </TableCell>
                                                                            <TableCell>{m.pickup_location_name}</TableCell>
                                                                            <TableCell>
                                                                                <ReactMoment parse="MM-DD-YYYY HH:mm:ss" format="MM/DD/YYYY HH:mm">
                                                                                    {m.arrival_datetime}
                                                                                </ReactMoment>
                                                                            </TableCell>
                                                                            <TableCell>{m.destination_location_name}</TableCell>
                                                                        </TableRow>
                                                                    );
                                                                })
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </React.Fragment>
                                );
                            })}
                        </TableBody>
                    </Table>}
                    { recurringTourMovements.length > 0 &&
                    <Toolbar>
                        <div className={classes.title}>
                            <Typography variant="title" id="tableTitle">
                                Changed Movements
                            </Typography>
                        </div>
                        <div className={classes.spacer} />
                    </Toolbar>}
                    { recurringTourMovements.length > 0 &&
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow className={classes.headRowStyle}>
                                <TableCell className={classes.headCellStyle} padding='checkbox'>Charter ID</TableCell>
                                <TableCell className={classes.headCellStyle} padding='none'>Movement ID</TableCell>
                                <TableCell className={classes.headCellStyle} style={{ padding: '4px 5px' }}>Movement Vehicle</TableCell>
                                <TableCell className={classes.headCellStyle} style={{ padding: '4px 5px' }}>Spot Date</TableCell>
                                <TableCell className={classes.headCellStyle} padding='none'>Vehicle Change</TableCell>
                                <TableCell className={classes.headCellStyle} padding='none'>Start Terminal Change</TableCell>
                                <TableCell className={classes.headCellStyle} padding='none'>End Terminal Change</TableCell>
                                <TableCell className={classes.headCellStyle} padding='none'>Capacity Change</TableCell>
                                <TableCell className={classes.headCellStyle} padding='none'>Type</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortingHelper.stableSort(recurringTourMovements, sortingHelper.getSorting('asc', 'spot_datetime')) 
                            .map(m => {
                                return (
                                    <React.Fragment key={m.engine_movement_id}>
                                        <TableRow key={m.engine_movement_id}>
                                            <TableCell component="th" scope="row" padding='checkbox'>
                                                {m.charter.charter_id}
                                            </TableCell>
                                            <TableCell component="th" scope="row" padding="none">
                                                {m.movement_id}
                                            </TableCell>
                                            <TableCell style={{ padding: '4px 5px' }}>
                                                {m.vehicle_index}
                                            </TableCell>
                                            <TableCell style={{ padding: '4px 5px' }}>
                                                <ReactMoment parse="MM-DD-YYYY HH:mm:ss" format="MM/DD/YYYY HH:mm">
                                                {m.spot_datetime}
                                                </ReactMoment>
                                            </TableCell>
                                            <TableCell component="th" scope="row" padding="none">
                                            { m.show_tour &&
                                                <React.Fragment>
                                                    <span className='compare-previous-value'>{m.previous_movement.tour.vehicle_id}</span>
                                                    <ArrowForward className='compare-arrow'/> 
                                                    <span className='compare-changed-value'>{m.tour.vehicle_id}</span>
                                                </React.Fragment>
                                            }
                                            </TableCell>
                                            <TableCell component="th" scope="row" padding="none">
                                            {m.show_start_terminal &&
                                                <React.Fragment>
                                                    {m.previous_movement.tour.is_subout && <span className='compare-previous-value'>SUBOUT</span>}
                                                    {!m.previous_movement.tour.is_subout && <span className='compare-previous-value'>{m.previous_movement.tour.start_terminal.base_name}</span>}
                                                    <ArrowForward className='compare-arrow'/>
                                                    {m.tour.is_subout && <span className='compare-changed-value'>SUBOUT</span>}
                                                    {!m.tour.is_subout && <span className='compare-changed-value'>{m.tour.start_terminal.base_name}</span>}
                                                </React.Fragment>
                                            }
                                            </TableCell>
                                            <TableCell component="th" scope="row" padding="none">
                                            {m.show_end_terminal &&
                                                <React.Fragment>
                                                    {m.previous_movement.tour.is_subout && <span className='compare-previous-value'>SUBOUT</span>}
                                                    {!m.previous_movement.tour.is_subout && <span className='compare-previous-value'>{m.previous_movement.tour.end_terminal.base_name}</span>}
                                                    <ArrowForward className='compare-arrow'/>
                                                    {m.tour.is_subout && <span className='compare-changed-value'>SUBOUT</span>}
                                                    {!m.tour.is_subout && <span className='compare-changed-value'>{m.tour.end_terminal.base_name}</span>}
                                                </React.Fragment>
                                            }
                                            </TableCell>
                                            <TableCell component="th" scope="row" padding="none">
                                            {m.show_vehicle_type && 
                                                <React.Fragment>
                                                <span className='compare-previous-value'>{m.previous_movement.tour.vehicle_type}</span> 
                                                <ArrowForward className='compare-arrow'/> 
                                                <span className='compare-changed-value'>{m.tour.vehicle_type}</span>
                                                </React.Fragment>
                                            }
                                            </TableCell>
                                            <TableCell component="th" scope="row" padding="none">
                                                {m.movement_type === 'contract' ? 'Contract' : 'Hire'}
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                );
                            })}
                        </TableBody>
                    </Table>}
                    { deletedTours.length > 0 &&
                    <Toolbar>
                        <div className={classes.title}>
                            <Typography variant="title" id="tableTitle">
                                Deleted Tours
                            </Typography>
                        </div>
                        <div className={classes.spacer} />
                    </Toolbar>}
                    { deletedTours.length > 0 &&
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow className={classes.headRowStyle}>
                                <TableCell className={classes.headCellStyle} padding='default'>Vehicle ID</TableCell>
                                <TableCell className={classes.headCellStyle} padding='none'>Start Date</TableCell>
                                <TableCell className={classes.headCellStyle} padding='none'>Start Terminal Name</TableCell>
                                <TableCell className={classes.headCellStyle} padding='none'>End Date</TableCell>
                                <TableCell className={classes.headCellStyle} padding='none'>End Terminal Name</TableCell>
                                <TableCell className={classes.headCellStyle} padding='none'>Vehicle Type</TableCell>
                                <TableCell className={classes.headCellStyle} padding='none'>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {deletedTours.map(n => {
                                return (
                                    <React.Fragment key={n.id}>
                                        <TableRow key={n.id} className={classes.deletedRowStyle}>
                                            <TableCell component="th" scope="row" padding='default'>{n.vehicle_id}</TableCell>
                                            <TableCell component="th" scope="row" padding="none">
                                                <ReactMoment parse="MM-DD-YYYY HH:mm:ss" format="MM/DD/YYYY HH:mm">
                                                    {n.start_datetime}
                                                </ReactMoment>
                                            </TableCell>
                                            <TableCell component="th" scope="row" padding="none">{n.is_subout ? 'SUBOUT' : n.start_terminal.base_name}</TableCell>
                                            <TableCell component="th" scope="row" padding="none">
                                                <ReactMoment parse="MM-DD-YYYY HH:mm:ss" format="MM/DD/YYYY HH:mm">
                                                    {n.end_datetime}
                                                </ReactMoment>
                                            </TableCell>
                                            <TableCell component="th" scope="row" padding="none">{n.is_subout ? 'SUBOUT' : n.end_terminal.base_name}</TableCell>
                                            <TableCell component="th" scope="row" padding="none">{n.vehicle_type}</TableCell>
                                            <TableCell component="th" scope="row" padding="none">
                                                {n.is_locked && "Locked"}
                                                {!n.is_locked && n.is_on_hold && "On Hold"}
                                            </TableCell>
                                        </TableRow>
                                        {n.movements.length > 0 &&
                                            <TableRow className={classes.deletedRowStyle}>
                                                <TableCell colSpan={'100%'}>
                                                    <Table className={classes.movementsTable}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Charter ID</TableCell>
                                                                <TableCell>Movement ID</TableCell>
                                                                <TableCell>Spot Date</TableCell>
                                                                <TableCell>Pickup</TableCell>
                                                                <TableCell>Arrival Date</TableCell>
                                                                <TableCell>Destination</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                sortingHelper.stableSort(n.movements, sortingHelper.getSorting('asc', 'spot_datetime'))
                                                                .filter (m => {return m != null;})
                                                                .map(m => {
                                                                    return (
                                                                        <TableRow
                                                                            role="checkbox"
                                                                            key={m.engine_movement_id + n.tour_id}>
                                                                            <TableCell>{m.charter.charter_id}</TableCell>
                                                                            <TableCell>{m.movement_id}</TableCell>
                                                                            <TableCell>
                                                                                <ReactMoment parse="MM-DD-YYYY HH:mm:ss" format="MM/DD/YYYY HH:mm">
                                                                                    {m.spot_datetime}
                                                                                </ReactMoment>
                                                                            </TableCell>
                                                                            <TableCell>{m.pickup_location_name}</TableCell>
                                                                            <TableCell>
                                                                                <ReactMoment parse="MM-DD-YYYY HH:mm:ss" format="MM/DD/YYYY HH:mm">
                                                                                    {m.arrival_datetime}
                                                                                </ReactMoment>
                                                                            </TableCell>
                                                                            <TableCell>{m.destination_location_name}</TableCell>
                                                                        </TableRow>
                                                                    );
                                                                })
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </React.Fragment>
                                );
                            })}
                        </TableBody>
                    </Table>}
                </Paper>
            );
        }
    }
}

PlannerCompareView.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PlannerCompareView);