import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import sortingHelper from '../../utils/sortingHelper'
import Button from '@material-ui/core/Button';
import SortTableHead from '../global/sort_table_head';
import ReactMoment from 'react-moment';
import IconButton from '@material-ui/core/IconButton';
import Warning from '@material-ui/icons/Warning';//status = mismatch
import Lock from '@material-ui/icons/Lock';//status = locked
import Snooze from '@material-ui/icons/Snooze';//status = on hold
import ExpandMore from '@material-ui/icons/ExpandMore';
import DoneAll from '@material-ui/icons/DoneAll';
import ExpandLess from '@material-ui/icons/ExpandLess';
import blue from '@material-ui/core/colors/blue';
import HistoryIcon from '@material-ui/icons/History';
import Refresh from '@material-ui/icons/Refresh';
import { Link } from 'react-router-dom';
import NotificationsActive from '@material-ui/icons/NotificationsActive';
import Notifications from '@material-ui/icons/Notifications';
import FilterListIcon from '@material-ui/icons/FilterList';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Badge from '@material-ui/core/Badge';
import Cookies from 'universal-cookie';
import EnvConfig from '../../config/config';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Today from '@material-ui/icons/Today';
import DateRange from '@material-ui/icons/DateRange';
import ExposurePlus1 from '@material-ui/icons/ExposurePlus1';
import Moment from 'moment';
import FormControl from '@material-ui/core/FormControl';
import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

const cookies = new Cookies();
const dataRefreshIntervalSeconds = 30;

const lineNumberCustomStyle = {
  width: '50px',
  padding: '0 0 0 10px'
}

const tourIdCustomStyle = {
  width: '65px',
  padding: '0 0 0 10px'
}


const moveIdCustomStyle = {
  width: '120px',
  padding: '0 0 0 10px'
}
const expandableIconCustomStyle = {
  padding: '0 0 0 0px'
}
const checkBoxColumnHead = {
  padding: '0 0 0 24px'
}

const statusCustomStyle = {
  minWidth: '80px'
}

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 5%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    margin: theme.spacing.unit,
  },
  cssRoot: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
    },
  },
  controls: {
    borderTop: '1px solid #CCC',
    marginTop: '5px'
  },
  activeTooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)'
  },
  formControl: {
    margin: '8px 0',
    width: '100%',
  },
});

let TripPlannerTableToolbar = props => {
  const { toursSelected, toursExpanded, classes, isMovementSelected, handleEditMovementClick, handleHoldTourClick, handleLockTourClick, toolbarTitle, editMode, hasDataErrors, notificationsAnchorEl, notifications, handleNotificationsClick, handleNotificationsClose, handleClearNotifications, filterAnchorEl, handleFilterClick, handleFilterClose, handleFilterDateActionClick, handleExpandAllClick, handleUnexpandAllClick, engineIsRunning, dateFilterType, handleResetFilterActionClick, handleDoneFilterActionClick, movementFilterValue, handleMovementFilterChange, movementOptions, handleCharterFilterChange, charterOptions, charterFilterValue, startTerminalFilterValue, handleStartTerminalFilterChange, selectAll, startTerminalOptions, customDateFilterValue, handleCustomDateFilterChange, user, showHoldTourButton, showUnholdTourButton } = props;

  let notificationsCount = notifications.length
  let changeNotifications = false
  if (hasDataErrors) {
    notificationsCount += 1
  }
  if (notifications.length > 0) {
    changeNotifications = true
  }

  return (
    <Toolbar
      className={classNames(classes.root)}
    >
      <div className={classes.title}>
        <Typography variant="title" id="tableTitle">
          {toolbarTitle}
        </Typography>
      </div>
      <Link to='/trip-planner-history' className={engineIsRunning ? 'history-link-loading' : 'history-link'} >
        {engineIsRunning &&
          <Refresh />
        }
        {!engineIsRunning &&
          <HistoryIcon />}
      </Link>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        <React.Fragment>
          {toursSelected.length > 0 && showHoldTourButton(toursSelected, 'is_on_hold') &&
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={event => handleHoldTourClick(event, true, toursSelected)}
              disabled={engineIsRunning}
            >
              Hold Tour
           </Button>
          }
          {toursSelected.length > 0 && showUnholdTourButton(toursSelected, 'is_on_hold') &&
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={event => handleHoldTourClick(event, false, toursSelected)}
              disabled={engineIsRunning}
            >
              Unhold Tour
          </Button>}
          {toursSelected.length > 0 && showHoldTourButton(toursSelected, 'is_locked') &&
            <Button
              variant="contained"
              color="primary"
              className={classNames(classes.button, classes.cssRoot)}
              onClick={event => handleLockTourClick(event, true, toursSelected)}
              disabled={engineIsRunning}
            >
              Lock Tour
          </Button>}
          {toursSelected.length > 0 && showUnholdTourButton(toursSelected, 'is_locked') &&
            <Button
              variant="contained"
              color="primary"
              className={classNames(classes.button, classes.cssRoot)}
              onClick={event => handleLockTourClick(event, false, toursSelected)}
              disabled={engineIsRunning}
            >
              Unlock Tour
          </Button>}
          {isMovementSelected && user != null && user.is_superuser &&
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={event => handleEditMovementClick(event)}
              disabled={engineIsRunning}
            >
              Edit Movement
          </Button>}
        </React.Fragment>
        <Tooltip title="Expand All">
          <IconButton aria-label="expandAll" onClick={handleExpandAllClick}> <ExpandMore />
          </IconButton>
        </Tooltip>
        <Tooltip title="Collapse All">
          <IconButton aria-label="collapseAll" onClick={handleUnexpandAllClick}> <ExpandLess />
          </IconButton>
        </Tooltip>
        <Tooltip title="Filter list">
          <IconButton aria-label="Filter list" onClick={handleFilterClick}>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
        <Popover
          id="simple-popper"
          open={Boolean(filterAnchorEl)}
          anchorEl={filterAnchorEl}
          onClose={handleFilterClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Card className={classes.card}>
            <div className={classes.details}>
              <CardContent className={classes.content}>
                <Typography variant="subheading" color="textSecondary">
                  Filter Tours
                  </Typography>
                <div className={classes.controls}>
                  <Typography color="textSecondary">
                    Date
                    </Typography>
                  <Tooltip title="In 24 Hours">
                    <IconButton onClick={event => handleFilterDateActionClick(event, 'Today')} className={dateFilterType === 'Today' ? classes.activeTooltip : ''}>
                      <Today />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="This Week">
                    <IconButton onClick={event => handleFilterDateActionClick(event, 'ThisWeek')} className={dateFilterType === 'ThisWeek' ? classes.activeTooltip : ''}>
                      <ExposurePlus1 />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Next Week">
                    <IconButton onClick={event => handleFilterDateActionClick(event, 'NextWeek')} className={dateFilterType === 'NextWeek' ? classes.activeTooltip : ''}>
                      <DateRange />
                    </IconButton>
                  </Tooltip>
                </div>
                <div className={classes.controls}>
                  <FormControl required className={classes.formControl}>
                    <TextField
                      id="date"
                      label="Custom Date"
                      type="date"
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={customDateFilterValue}
                      onChange={event => handleCustomDateFilterChange(event)}
                    />
                  </FormControl>
                </div>
                <div className={classes.controls}>
                  <FormControl required className={classes.formControl}>
                    <Select
                      value={startTerminalFilterValue}
                      onChange={handleStartTerminalFilterChange}
                      isClearable='true'
                      name="start-terminal-filter"
                      options={startTerminalOptions}
                      placeholder='Start Terminal'
                      inputProps={{
                        id: 'start-terminal-filter',
                      }}
                    >
                      {
                        startTerminalOptions.map(startTerminalOption => {
                          return (
                            <MenuItem key={startTerminalOption.value} value={startTerminalOption.value}>{startTerminalOption.label}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                  
                </div>
                <div className={classes.controls}>
                  <FormControl required className={classes.formControl}>
                    <Select
                      value={charterFilterValue}
                      onChange={handleCharterFilterChange}
                      isClearable='true'
                      name="charter-filter"
                      options={charterOptions}
                      placeholder='Charter ID'
                      inputProps={{
                        id: 'charter-filter',
                      }}
                    >
                      {
                        charterOptions !== undefined && charterOptions.map(charterOption => {
                          return (
                            <MenuItem key={charterOption.value} value={charterOption.value}>{charterOption.label}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>

                </div>
                <div className={classes.controls}>
                  <FormControl required className={classes.formControl}>
                    <Select
                      value={movementFilterValue}
                      onChange={handleMovementFilterChange}
                      isClearable='true'
                      name="movement-filter"
                      options={movementOptions}
                      placeholder='Movement ID'
                      inputProps={{
                        id: 'movement-filter',
                      }}
                    >
                      {
                        movementOptions.map(movementOption => {
                          return (
                            <MenuItem key={movementOption.value} value={movementOption.value}>{movementOption.label}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                </div>
                <div className={classes.controls}>
                  <Button variant="contained"
                    className={classes.button}
                    onClick={event => handleResetFilterActionClick(event)}
                  >
                    Reset
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={event => handleDoneFilterActionClick(event)}
                  >
                    DONE
                  </Button>
                </div>
              </CardContent>
            </div>
          </Card>
        </Popover>
        <Tooltip title="Notifications">
          <IconButton aria-label="Notifications" onClick={handleNotificationsClick}>
            {notificationsCount > 0 &&
              <Badge badgeContent={notificationsCount} color="secondary">
                <NotificationsActive />
              </Badge>}
            {notificationsCount === 0 &&
              <Notifications />}
          </IconButton>
        </Tooltip>
        <Popover
          id="popper-notification"
          open={Boolean(notificationsAnchorEl)}
          anchorEl={notificationsAnchorEl}
          onClose={handleNotificationsClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuList>
            {notifications.map(n => {
              const comparePath = '/trip-planner-compare/' + n.current_run + '/' + n.previous_run;
              return (<MenuItem key={n.id} component={Link} to={comparePath}>Changes on run {n.current_run}</MenuItem>)
            })}
            <MenuItem component={Link} to={'/charters/errors/'}>Charter Errors</MenuItem>
            {changeNotifications &&
              <MenuItem onClick={event => handleClearNotifications(event)}>Clear change notifications</MenuItem>}
          </MenuList>
        </Popover>
      </div>
    </Toolbar>
  );
};

TripPlannerTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

TripPlannerTableToolbar = withStyles(toolbarStyles)(TripPlannerTableToolbar);

const tourColorBoxBase = {
  width: '1%',
  height: '48px',
}

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  movementsTable: {
    border: '1px solid rgba(224, 224, 224, 1)'
  },
  tourColorBoxBlue: {
    ...tourColorBoxBase,
    backgroundColor: '#3f51b5',
  },
  tourColorBoxGreen: {
    ...tourColorBoxBase,
    backgroundColor: '#4caf50',
  },
  tourColorBoxRed: {
    ...tourColorBoxBase,
    backgroundColor: '#ff1744',
  },
  tourColorBoxBlack: {
    ...tourColorBoxBase,
    backgroundColor: '#424242',
  },
  onHoldStyle: {
    backgroundColor: 'rgba(0, 0, 255, 0.2) !important'
  },
  heldStyle: {
    backgroundColor: 'rgba(255,193,7, 0.5) !important'
  }
});

class TripPlannerTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      order: 'asc',
      orderBy: 'start_datetime',
      notifications: [],
      page: 0,
      rowsPerPage: 25,
      toursSelected: [],
      toursExpanded: [],
      movementSelected: null,
      notificationsAnchorEl: null,
      filterAnchorEl: null,
      dateFilterType: 'None',
      movementFilterValue: 'None',
      charterFilterValue: 'None',
      startTerminalFilterValue: 'None',
      customDateFilterValue: '',
      currentLine: 1,
      selectAll: false,
    };
    this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      Promise.all([
        fetch(EnvConfig.getConfig().apiUrl + "/api/notifications/?active", getAuthorization)
      ])
        .then(function (responseArray) {
          const unauthorized = responseArray.find(function (response) {
            return response.ok === false && response.status === 401;
          });
          if (unauthorized !== undefined) {
            window.location = "/login";
          }
          return responseArray;
        })
        .then(([
          notificationsResponse,
        ]) => Promise.all([
          notificationsResponse.json()
        ]))
        .then(([notificationsData]) => {
          this.setState({
            notifications: notificationsData.results
          });
        },
          (error) => {
            console.log(error);
          })

    }, 1000 * dataRefreshIntervalSeconds
    );

    const getAuthorization = {
      method: 'GET',
      headers: {
        Authorization: 'Token ' + cookies.get('token')
      }
    };

    Promise.all([
      fetch(EnvConfig.getConfig().apiUrl + "/api/notifications/?active", getAuthorization),
    ])
      .then(([
        notificationsResponse,
      ]) => Promise.all([
        notificationsResponse.json(),
      ]))
      .then(([notificationsData]) => {
        this.setState({
          notifications: notificationsData.results,
        });
      },
        (error) => {
          console.log(error);
        })
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, name) => {
    var newToursSelected = this.state.toursSelected.slice();
    var filteredTours = this.filter(this.props.tours);
    var sortedTours = sortingHelper.stableSort(filteredTours, sortingHelper.getSorting(this.state.order, this.state.orderBy));

    if (event.target.checked) {
      const total = this.state.page * this.state.rowsPerPage;

      for (var i = total; i < total + this.state.rowsPerPage; i++) {
        if (sortedTours[i].movements.length > 0 && !newToursSelected.includes(sortedTours[i].id)) {
          newToursSelected.push(sortedTours[i].id);
        }
      }
    } else {
      const total = this.state.page * this.state.rowsPerPage;
      var newToursSelected = [];

      for (var i = total; i < total + this.state.rowsPerPage; i++) {
        if (newToursSelected.includes(sortedTours[i].id)) {
          const tourIndex = newToursSelected.indexOf(sortedTours[i].id)
          newToursSelected = newToursSelected.slice(0, tourIndex).concat(newToursSelected.slice(tourIndex + 1, newToursSelected.length))
        }
      }
    }
    this.setState({ selectAll: event.target.checked, toursSelected: newToursSelected })
  };

  showHoldTourButton = (toursSelected, holdOrLock) => {
    const tourHoldStatus = []
    var allFalse = true
    var someFalse = false
    for (var i in toursSelected) {
      for (var t in this.props.tours) {
        if (this.props.tours[t].id == toursSelected[i]) {
          tourHoldStatus.push(this.props.tours[t][holdOrLock])
        }
      }
    }
    for (var t in tourHoldStatus) {
      if (tourHoldStatus[t] == true) {
        allFalse = false;
        break;
      }
    }

    if (tourHoldStatus.includes(true) && tourHoldStatus.includes(false)) {
      someFalse = true
    }
    if (allFalse == true || someFalse == true) {
      return true;
    }
  }

  showUnholdTourButton = (toursSelected, holdOrLock) => {
    const tourHoldStatus = []
    var allFalse = true
    for (var i in toursSelected) {
      for (var t in this.props.tours) {
        if (this.props.tours[t].id == toursSelected[i]) {
          tourHoldStatus.push(this.props.tours[t][holdOrLock])
        }
      }
    }
    for (var t in tourHoldStatus) {
      if (tourHoldStatus[t] == false) {
        allFalse = false;
        break;
      }
    }
    return allFalse;
  }


  handleSelectTour = (event, tour) => {
    event.stopPropagation();
    var newToursSelected = this.state.toursSelected.slice();
    if (!this.state.toursSelected.includes(tour.id) && event.target.checked) {
      newToursSelected.push(tour.id);
    }
    if (this.state.toursSelected.includes(tour.id) && !event.target.checked) {
      const tourIndex = newToursSelected.indexOf(tour.id);
      newToursSelected = newToursSelected.slice(0, tourIndex).concat(newToursSelected.slice(tourIndex + 1, newToursSelected.length));
    }

    this.setState({ toursSelected: newToursSelected })
  }

  handleTourClick = (event, tour) => {
    const newToursExpanded = this.state.toursExpanded.slice();
    if (!this.state.toursExpanded.includes(tour.id) && tour.movements.length > 0) {
      newToursExpanded.push(tour.id);
    }
    this.setState({ toursExpanded: newToursExpanded, movementSelected: null });
  };

  handleExpandAllClick = (event) => {
    const total = this.state.page * this.state.rowsPerPage;
    var newToursExpanded = this.state.toursExpanded.slice();

    var filteredTours = this.filter(this.props.tours);
    var sortedTours = sortingHelper.stableSort(filteredTours, sortingHelper.getSorting(this.state.order, this.state.orderBy));

    var tour_size_limit = Math.min(total + this.state.rowsPerPage, sortedTours.length);
    for (var i = total; i < tour_size_limit; i++) {
      var tour = sortedTours[i]
      if (!this.state.toursExpanded.includes(tour.id) && tour.movements.length > 0) {
        newToursExpanded.push(tour.id);
      }
    }
    this.setState({ toursExpanded: newToursExpanded });
  }

  handleUnexpandAllClick = (event) => {
    event.stopPropagation();
    const total = this.state.page * this.state.rowsPerPage;
    var newToursExpanded = [];

    var filteredTours = this.filter(this.props.tours);
    var sortedTours = sortingHelper.stableSort(filteredTours, sortingHelper.getSorting(this.state.order, this.state.orderBy));

    var tour_size_limit = Math.min(total + this.state.rowsPerPage, sortedTours.length);
    for (var i = total; i < tour_size_limit; i++) {
      if (newToursExpanded.includes(sortedTours[i].id)) {
        var tourIndex = newToursExpanded.indexOf(sortedTours[i].id);
        newToursExpanded = newToursExpanded.slice(0, tourIndex).concat(newToursExpanded.slice(tourIndex + 1, newToursExpanded.length));
      }
    }
    this.setState({ toursExpanded: newToursExpanded });
  }

  handleMovementClick = (event, m) => {
    const selectedMovementTour = this.props.tours.find(t => {
      return t.id == m.tour_id;
    });

    this.setState({ movementSelected: m })
  }

  handleHoldTourClick = (event, isHold, tourIds) => {
    let postData = []
    postData = tourIds.map(t => { return { 'id': t, 'is_on_hold': isHold } })

    fetch(EnvConfig.getConfig().apiUrl + "/api/tours/bulk_update", {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Token ' + cookies.get('token')
      },
      body: JSON.stringify(postData),
    })
      .then(function (response) {
        if (response.ok === false && response.status === 401) {
          window.location = "/login";
        }
        return response;
      })
      .then(res => res.json())
      .then(
        (result) => {
          const newDataArray = this.props.tours.slice();

          for (var tour in tourIds) {
            const tourIndex = newDataArray.findIndex(function (t) {
              return t.id === tourIds[tour];
            });
            newDataArray[tourIndex].is_on_hold = isHold;
          }
          this.setState({ toursSelected: [], movementSelected: null, selectAll: false });
        },
        (error) => {
          console.log(error);
        }
      )
  }

  handleLockTourClick = (event, isLock, tourIds) => {
    let postData = []
    postData = tourIds.map(t => { return { 'id': t, 'is_locked': isLock } })

    fetch(EnvConfig.getConfig().apiUrl + "/api/tours/bulk_update", {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Token ' + cookies.get('token')
      },
      body: JSON.stringify(postData),
    })
      .then(function (response) {
        if (response.ok === false && response.status === 401) {
          window.location = "/login";
        }
        return response;
      })
      .then(res => res.json())
      .then(
        (result) => {

          const newDataArray = this.props.tours.slice();

          for (var tour in tourIds) {
            const tourIndex = newDataArray.findIndex(function (t) {
              return t.id === tourIds[tour];
            });

            newDataArray[tourIndex].is_locked = isLock;
          }
          this.setState({ toursSelected: [], movementSelected: null, selectAll: false });
        },
        (error) => {
          console.log(error);
        }
      )
  }

  handleEditMovementClick = event => {
    this.props.handleShowFeedbackForm(event, this.state.movementSelected, this.state.toursSelected);
  }

  handleChangePage = (event, page) => {
    this.setState({ page: page, currentLine: page * this.state.rowsPerPage + 1, toursSelected: [], selectAll: false });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value, currentLine: event.target.value * this.state.page + 1 });
  };

  handleUnselectTourClick = (event, tour) => {
    event.stopPropagation();
    var newToursExpanded = this.state.toursExpanded.slice();
    const tourIndex = newToursExpanded.indexOf(tour.id);
    newToursExpanded = newToursExpanded.slice(0, tourIndex).concat(newToursExpanded.slice(tourIndex + 1, newToursExpanded.length))
    this.setState({ toursExpanded: newToursExpanded });
  }

  handleNotificationsClick = event => {
    if (this.state.notifications.length > 0 || this.props.hasDataErrors) {
      this.setState({ notificationsAnchorEl: event.currentTarget });
    }
  };

  handleNotificationsClose = () => {
    this.setState({ notificationsAnchorEl: null });
  };

  handleClearNotifications = (event) => {

    fetch(EnvConfig.getConfig().apiUrl + "/api/notifications/clear_all", {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Token ' + cookies.get('token')
      },
    })
      .then(res => res)
      .then(
        (result) => {
          this.setState({ notificationsAnchorEl: null, notifications: [] });
        },
        (error) => {
          console.log(error);
        }
      )
  };

  handleFilterClick = event => {
    this.setState({ filterAnchorEl: event.currentTarget });
  };

  handleFilterClose = () => {
    this.setState({ filterAnchorEl: null });
  }

  handleFilterDateActionClick = (event, type) => {
    this.setState({ dateFilterType: type, customDateFilterValue: '' });
  }

  handleResetFilterActionClick = (event) => {
    this.setState({ dateFilterType: 'None', filterAnchorEl: null, movementFilterValue: 'None', charterFilterValue: 'None', startTerminalFilterValue: 'None', customDateFilterValue: '' });
  }
  handleDoneFilterActionClick = (event) => {
    this.setState({ filterAnchorEl: null });
  }

  handleMovementFilterChange = movementFilter => {
    if (movementFilter!=null){
    this.setState({ movementFilterValue: movementFilter })
    } else {
      this.setState({movementFilterValue: 'None'});
    }
  }

  handleStartTerminalFilterChange = startTerminalFilter => {
    if (startTerminalFilter!=null){
      this.setState({ startTerminalFilterValue: startTerminalFilter })
    } else {
      this.setState({startTerminalFilterValue: 'None'});
    }
    
  }
  handleCharterFilterChange = charterFilter => {
    if (charterFilter!=null){
    this.setState({ charterFilterValue: charterFilter })
  } else {
    this.setState({charterFilterValue: 'None'});
  }
}

  handleCustomDateFilterChange = event => {
    console.log(event.target.value);
    this.setState({ customDateFilterValue: event.target.value, dateFilterType: 'None' })
  }

  isSelected = id => this.state.toursSelected.includes(id) ? true : false;

  isExpanded = id => this.state.toursExpanded != null ? this.state.toursExpanded.includes(id) : false;

  isMovementSelected = id => this.state.movementSelected != null ? this.state.movementSelected.engine_movement_id === id : false;

  getColorClassName = (tour) => {
    if (tour.movements.length === 0) {
      // reposition
      return this.props.classes.tourColorBoxBlack;
    }
    else if (tour.is_subout) {
      // tour is subout
      return this.props.classes.tourColorBoxRed;
    }
    else if (tour.start_terminal.base_serial_no !== tour.end_terminal.base_serial_no) {
      // end at different terminal
      return this.props.classes.tourColorBoxBlue;
    }
    else {
      return this.props.classes.tourColorBoxGreen;
    }
  }

  getTourTypeTitle = (tour) => {
    if (tour.movements.length === 0) {
      return "Reposition";
    }
    else if (tour.is_subout) {
      return "Subout";
    }
    else if (tour.start_terminal.base_serial_no !== tour.end_terminal.base_serial_no) {
      return "Ends at different terminal";
    }
    else {
      return "Starts and end at same terminal";
    }
  }

  getMovementIdList = (tour) => {
    let movementsList = '';
    if (tour.movements != null && tour.movements.length > 0) {
      let movementArray = sortingHelper.stableSort(tour.movements, sortingHelper.getSorting('asc', 'spot_datetime'))
        .map(m => { return m.movement_id });
      movementsList = movementArray.join(', ');
    }
    return movementsList
  }

  getTableHeader = [
    { id: 'color_id', numeric: false, disablePadding: true, label: '' },
    { id: 'id', numeric: false, disablePadding: true, label: 'Line No', customStyle: lineNumberCustomStyle, noSorting: true },
    { id: 'vehicle_id', numeric: false, disablePadding: true, label: 'Vehicle ID', customStyle: tourIdCustomStyle },
    { id: 'movement_ids', numeric: false, disablePadding: true, label: 'Movement IDs', customStyle: moveIdCustomStyle, noSorting: true },
    { id: 'booked_bases', numeric: false, disablePadding: true, label: 'Booked Bases', customStyle: moveIdCustomStyle },
    { id: 'start_datetime', numeric: false, disablePadding: true, label: 'Start Date' },
    { id: 'start_terminal.base_name', numeric: false, disablePadding: true, label: 'Start Terminal Name' },
    { id: 'end_datetime', numeric: false, disablePadding: true, label: 'End Date' },
    { id: 'end_terminal.base_name', numeric: false, disablePadding: true, label: 'End Terminal Name' },
    { id: 'vehicle_capacity', numeric: false, disablePadding: true, label: 'Vehicle Capacity' },
    { id: 'req_equip', numeric: false, disablePadding: true, label: 'Requested Equip' },
    { id: 'status', numeric: false, disablePadding: true, customStyle: statusCustomStyle, label: 'Status', noSorting: true },
    { id: 'price', numeric: false, disablePadding: true, customStyle: statusCustomStyle, label: 'Price', noSorting: true },
    { id: 'expandable_icon', numeric: false, disablePadding: true, customStyle: expandableIconCustomStyle, label: <DoneAll color="inherit" /> },
    { id: 'select_all', numeric: false, disablePadding: false, noSorting: true },

  ]

  filter = (tours) => {

    var newTours = tours.slice();

    if (this.state.movementFilterValue !== 'None') {
      newTours = newTours.filter(t => {
        const movementsFound = t.movements.filter(m => {
          return m.movement_id === this.state.movementFilterValue.label;
        });
        return movementsFound.length > 0;
      });
    }

    if (this.state.charterFilterValue !== 'None') {
      newTours = newTours.filter(t => {
        const movementsFound = t.movements.filter(m => {
          return m.charter.charter_id === this.state.charterFilterValue.value;
        });
        return movementsFound.length > 0;
      });
    }

    if (this.state.startTerminalFilterValue !== 'None') {
      const subouts = newTours.filter(t => {
        return t.start_terminal != undefined && t.start_terminal.base_serial_no == -1 && t.booked_bases.includes(this.state.startTerminalFilterValue.label)
      });

      newTours = newTours.filter(t => {
        return t.start_terminal != undefined && t.start_terminal.base_serial_no === this.state.startTerminalFilterValue.value;
      });

      subouts.map(m => {
        newTours.push(m)
      })
    }

    if (this.state.customDateFilterValue !== '') {
      newTours = newTours.filter(t => {
        const startTourMomentDate = Moment(t.start_datetime, 'MM-DD-YYYY HH:mm:ss');
        const endTourMomentDate = Moment(t.end_datetime, 'MM-DD-YYYY HH:mm:ss');
        const customDate = Moment(this.state.customDateFilterValue, 'YYYY-MM-DD');

        // return customDate.isBetween(startTourMomentDate, endTourMomentDate) || customDate.isSame(startTourMomentDate, 'day') || customDate.isSame(endTourMomentDate, 'day');
        return customDate.isSame(startTourMomentDate, 'day');
      });
    }
    else {
      if (this.state.dateFilterType !== 'None') {
        switch (this.state.dateFilterType) {
          case 'Today':
            newTours = newTours.filter(function (t) {
              const momentDate = Moment(t.start_datetime, 'MM-DD-YYYY HH:mm:ss');
              return Moment().diff(momentDate, 'days') >= 0 && Moment().diff(momentDate, 'days') <= 1;
            });
            break;
          case 'ThisWeek':
            newTours = newTours.filter(function (t) {
              const momentDate = Moment(t.start_datetime, 'MM-DD-YYYY HH:mm:ss');
              return Moment().diff(momentDate, 'days') <= 0 && Moment().diff(momentDate, 'days') >= -7;
            });
            break;
          case 'NextWeek':
            newTours = newTours.filter(function (t) {
              const momentDate = Moment(t.start_datetime, 'MM-DD-YYYY HH:mm:ss');
              return Moment().diff(momentDate, 'days') < -7 && Moment().diff(momentDate, 'days') >= -14;
            });
            break;
          case 'None':
          default:
            break;
        }
      }
    }

    return newTours;
  }
  
  render() {
    const { classes, toolbarTitle, editMode, engineIsRunning, movementOptions, charterOptions, hasDataErrors, startTerminalOptions, user } = this.props;
    const { order, orderBy, rowsPerPage, page, toursSelected, toursExpanded, movementSelected, notificationsAnchorEl, notifications, filterAnchorEl, dateFilterType, movementFilterValue, charterFilterValue, startTerminalFilterValue, customDateFilterValue, currentLine, selectAll, handleSelectAllClick } = this.state;
    const { handleEditMovementClick, handleHoldTourClick, handleLockTourClick, handleNotificationsClick, handleNotificationsClose, handleClearNotifications, handleFilterClick, handleFilterClose, handleFilterDateActionClick,handleResetFilterActionClick, handleDoneFilterActionClick, handleMovementFilterChange, handleCharterFilterChange, handleStartTerminalFilterChange, handleCustomDateFilterChange, handleExpandAllClick, handleUnexpandAllClick, showHoldTourButton, showUnholdTourButton } = this;
    const filteredTours = this.filter(this.props.tours);
    //console.log("filtered:"+filteredTours)
    //filteredTours.each({})
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    })
    let lineNo = currentLine;
    return (
      <Paper className={classes.root}>
        <TripPlannerTableToolbar toursSelected={toursSelected} toursExpanded={toursExpanded} isMovementSelected={movementSelected != null} handleEditMovementClick={handleEditMovementClick} handleHoldTourClick={handleHoldTourClick} handleLockTourClick={handleLockTourClick} toolbarTitle={toolbarTitle} editMode={editMode} hasDataErrors={hasDataErrors} notificationsAnchorEl={notificationsAnchorEl} handleNotificationsClick={handleNotificationsClick} handleNotificationsClose={handleNotificationsClose} handleClearNotifications={handleClearNotifications} notifications={notifications} filterAnchorEl={filterAnchorEl} handleFilterClick={handleFilterClick} handleFilterClose={handleFilterClose} handleFilterDateActionClick={handleFilterDateActionClick} handleExpandAllClick={handleExpandAllClick} handleUnexpandAllClick={handleUnexpandAllClick} engineIsRunning={engineIsRunning} dateFilterType={dateFilterType} handleResetFilterActionClick={handleResetFilterActionClick} handleDoneFilterActionClick={handleDoneFilterActionClick} movementFilterValue={movementFilterValue} movementOptions={movementOptions} charterOptions={charterOptions} handleMovementFilterChange={handleMovementFilterChange} handleCharterFilterChange={handleCharterFilterChange} charterFilterValue={charterFilterValue} startTerminalOptions={startTerminalOptions} startTerminalFilterValue={startTerminalFilterValue} handleStartTerminalFilterChange={handleStartTerminalFilterChange} handleCustomDateFilterChange={handleCustomDateFilterChange} customDateFilterValue={customDateFilterValue} user={user} selectAll={selectAll} handleSelectAllClick={handleSelectAllClick} showHoldTourButton={showHoldTourButton} showUnholdTourButton={showUnholdTourButton} />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <SortTableHead
              selectAll={selectAll}
              handleSelectAllClick={this.handleSelectAllClick}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rows={this.getTableHeader}
            />
            <TableBody>
              {sortingHelper.stableSort(filteredTours, sortingHelper.getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id);
                  const isExpanded = this.isExpanded(n.id);
                  return (
                    <React.Fragment key={n.id}>
                      <TableRow
                        hover
                        onClick={event => this.handleTourClick(event, n)}
                        role="checkbox"
                        aria-checked={isExpanded}
                        tabIndex={-1}
                        key={n.id}
                        selected={isExpanded}
                        className={isSelected ? classes.onHoldStyle : n.is_locked || n.is_on_hold ? classes.heldStyle : ''}
                      >
                        <TableCell title={this.getTourTypeTitle(n)} component="th" scope="row" padding="none" className={this.getColorClassName(n)}></TableCell>
                        <TableCell component="th" scope="row" padding="checkbox">{lineNo++}</TableCell>
                        <TableCell component="th" scope="row" padding="checkbox">{n.vehicle_id == null || n.vehicle_id == undefined ? '' : n.vehicle_id}</TableCell>
                        <TableCell component="th" scope="row" padding="checkbox">{this.getMovementIdList(n)}</TableCell>
                        <TableCell component="th" scope="row" padding="checkbox">{n.booked_bases}</TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          {n.on_tour && 'On tour'}
                          {!n.on_tour &&
                            <ReactMoment parse="MM-DD-YYYY HH:mm:ss" format="MM/DD/YYYY HH:mm">
                              {n.start_datetime}
                            </ReactMoment>}
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">{n.is_subout ? 'SUBOUT' : n.start_terminal.base_name}</TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          {n.on_tour && 'On tour'}
                          {!n.on_tour &&
                            <ReactMoment parse="MM-DD-YYYY HH:mm:ss" format="MM/DD/YYYY HH:mm">
                              {n.end_datetime}
                            </ReactMoment>}
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">{n.is_subout ? 'SUBOUT' : n.end_terminal.base_name}</TableCell>
                        <TableCell component="th" scope="row" padding="none">{n.vehicle_capacity}</TableCell>
                        <TableCell component="th" scope="row" padding="none">{n.facilities}</TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          {n.is_locked && 
                          <Tooltip title="Locked">
                          <Lock className="status-icon" color="primary"/></Tooltip>}
                          {!n.is_locked && n.is_on_hold && 
                          <Tooltip title="On Hold">
                          <Snooze className="status-icon" color="primary"/></Tooltip>}
                          {n.allocationMismatch && 
                          <Tooltip title="Allocation Mismatch">
                          <Warning className="status-icon" color="primary" /></Tooltip>}
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                        {(n.totalPrice>0) &&
                          formatter.format(n.totalPrice)
                        }
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          {!isExpanded && n.movements.length > 0 &&
                            <IconButton color="primary" className={classes.button} aria-label="Expand Quote">
                              {n.movements.length > 1 &&
                                <DoneAll color="primary" />
                              }
                              {n.movements.length == 1 &&
                                <ExpandMore color="primary" />
                              }
                            </IconButton>}
                          {isExpanded && n.movements.length > 0 &&
                            <IconButton color="primary" className={classes.button} aria-label="Un-Expand Quote" onClick={event => this.handleUnselectTourClick(event, n)}>
                              <ExpandLess color="primary" />
                            </IconButton>}
                        </TableCell>
                        <TableCell>
                          {n.movements.length > 0 &&
                            <Checkbox checked={this.isSelected(n.id)} onClick={event => this.handleSelectTour(event, n)} value="selected" />}
                        </TableCell>
                      </TableRow>
                      {isExpanded && n.movements.length > 0 &&
                        <TableRow>
                          <TableCell colSpan={'100%'}>
                            <Table className={classes.movementsTable}>
                              <TableHead>
                                <TableRow>
                                  <TableCell style={{ paddingRight: '5px' }}>Charter ID</TableCell>
                                  <TableCell padding='none'>Movement ID</TableCell>
                                  <TableCell style={{ padding: '4px 5px' }}>Type</TableCell>
                                  <TableCell style={{ padding: '4px 5px' }}>Company</TableCell>
                                  <TableCell style={{ padding: '4px 5px' }}>Spot Date</TableCell>
                                  <TableCell style={{ padding: '4px 5px' }}>Pickup</TableCell>
                                  <TableCell style={{ padding: '4px 5px' }}>Leave Date</TableCell>
                                  <TableCell style={{ padding: '4px 5px' }}>Destination</TableCell>
                                  <TableCell style={{ padding: '4px 3px' }}>Day 1 Road Hours</TableCell>
                                  <TableCell style={{ padding: '4px 3px' }}>Day 1 Duty Hours</TableCell>
                                  <TableCell style={{ padding: '4px 3px' }}>Total Duty Hours</TableCell>
                                  <TableCell style={{ padding: '4px 3px' }}>Round Trip</TableCell>
                                  <TableCell style={{ padding: '4px 3px' }}>Price</TableCell>
                                  <TableCell style={{ padding: '4px 3px' }}>Allocated Vehicle</TableCell>
                                  <TableCell style={{ padding: '4px 5px 4px 3px' }}>Base</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {
                                  sortingHelper.stableSort(n.movements, sortingHelper.getSorting('asc', 'spot_datetime'))
                                    .map(m => {
                                      const isMovementSelected = this.isMovementSelected(m.engine_movement_id);
                                      return (
                                        <TableRow
                                          hover
                                          onClick={event => this.handleMovementClick(event, m)}
                                          role="checkbox"
                                          tabIndex={-1}
                                          selected={isMovementSelected}
                                          key={m.engine_movement_id}
                                          className={isMovementSelected ? classes.onHoldStyle : ''}>
                                          <TableCell style={{ paddingRight: '5px' }}>{m.charter.charter_id}</TableCell>
                                          <TableCell padding='none'>{m.movement_id}</TableCell>
                                          <TableCell style={{ padding: '4px 5px' }}>{m.movement_type === 'contract' ? 'Contract' : 'Hire'}</TableCell>
                                          <TableCell style={{ padding: '4px 3px' }}>{m.movement_company}</TableCell>
                                          <TableCell style={{ padding: '4px 5px' }}>
                                            <ReactMoment parse="MM-DD-YYYY HH:mm:ss" format="MM/DD/YYYY HH:mm">
                                              {m.spot_datetime}
                                            </ReactMoment>
                                          </TableCell>
                                          <TableCell style={{ padding: '4px 5px' }}>{m.pickup_formatted_address != null && m.pickup_formatted_address != '' ? m.pickup_formatted_address.replace(", USA", "") : m.pickup_location_name}</TableCell>
                                          <TableCell style={{ padding: '4px 5px' }}>
                                            <ReactMoment parse="MM-DD-YYYY HH:mm:ss" format="MM/DD/YYYY HH:mm">
                                              {m.is_round_trip ? m.back_datetime : m.leave_datetime}
                                            </ReactMoment>
                                          </TableCell>
                                          <TableCell style={{ padding: '4px 5px' }}>{m.destination_formatted_address != null && m.destination_formatted_address != '' ? m.destination_formatted_address.replace(", USA", "") : m.destination_location_name}</TableCell>
                                          <TableCell style={{ padding: '4px 3px' }}>{m.day_1_road_hours}</TableCell>
                                          <TableCell style={{ padding: '4px 3px' }}>{m.day_1_duty_hours}</TableCell>
                                          <TableCell style={{ padding: '4px 3px' }}>{m.total_duty_hours}</TableCell>
                                          <TableCell style={{ padding: '4px 3px' }}>{m.is_round_trip && 'YES'}</TableCell>
                                          <TableCell style={{ padding: '4px 3px' }}>{(m.vehicle_price>0) && formatter.format(m.vehicle_price)}</TableCell>
                                          <TableCell style={{ padding: '4px 3px' }}>{m.allocated_vehicle_id != 0 ? m.allocated_vehicle_id : 'N/A'}</TableCell>
                                          <TableCell style={{ padding: '4px 5px 4px 3px' }}>{m.booked_base !== null ? m.booked_base : ''}</TableCell>
                                        </TableRow>
                                      );
                                    })
                                }
                              </TableBody>
                            </Table>
                          </TableCell>
                        </TableRow>
                      }
                    </React.Fragment>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          component="div"
          count={filteredTours.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

TripPlannerTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TripPlannerTable);