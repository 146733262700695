import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import sortingHelper from '../../utils/sortingHelper'
import SortTableHead from '../global/sort_table_head';
import ReactMoment from 'react-moment';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import InfoIcon from '@material-ui/icons/Info';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import RepeatIcon from '@material-ui/icons/Repeat';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import EnvConfig from '../../config/config';
import Cookies from 'universal-cookie';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import NotificationsActive from '@material-ui/icons/NotificationsActive';
import Notifications from '@material-ui/icons/Notifications';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import { Link } from 'react-router-dom';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Drawer from '@material-ui/core/Drawer';
import LinearProgress from '@material-ui/core/LinearProgress';
import FilterListIcon from '@material-ui/icons/FilterList';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Today from '@material-ui/icons/Today';
import DateRange from '@material-ui/icons/DateRange';
import ExposurePlus1 from '@material-ui/icons/ExposurePlus1';
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import FormControl from '@material-ui/core/FormControl';
import Select from 'react-select';
import Moment from 'moment';


const cookies = new Cookies();
const envConfig = new EnvConfig();

const phoneHeader = {
  padding: "4px 6px 4px 6px",
  textAlign: "center",
}
const pricingTableCellHeader = {
  textAlign: "right",
  padding: "4px 6px 4px 6px"
}
const pricingTableInfoHeader = {
  textAlign: "center",
  padding: "6px"
}
const textColumnHeader = {
  textAlign: "left",
  padding: "6px"
}
const companyNameColumnHeader = {
  textAlign: "left",
  padding: "6px",
  minWidth: "150px"
}

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 20%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  acceptButton: {
    backgroundColor: '#4caf50',
    margin: theme.spacing.unit,
    '&:hover': {
      backgroundColor: '#357a38',
    },
  },
  toggleButton: {
    backgroundColor: theme.palette.background.default,
    margin: theme.spacing.unit,
    '&:hover': {
      backgroundColor: 'gray',
    },
  },
  hoverIcon: {
    backgroundColor: '#FF0000',
    fontSize:'18px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  button: {
    margin: theme.spacing.unit,
  },
  expandButton: {
    margin: theme.spacing.unit,
    width: '24px',
  },
  toggleContainer: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: `${theme.spacing.unit}px 0`,
  },
  formControl: {
    margin: '8px 0',
    width: '100%',
  },
  textFieldRight: {
    flexBasis: 200,
    marginLeft: theme.spacing.unit * 2
  }, 
});

function isAcceptableQuote (q) {
  if (q != null){
    var isAcceptable = true;
    q.movements.forEach(m => { 
      if (m.is_outdated) {
        isAcceptable = false;
      };
    })
    return isAcceptable;
  }
}

let QuoteListToolbar = props => {
  const { quoteSelected, classes, handleAcceptQuoteClick, handleRejectQuoteClick, hasQuoteErrors,hasTripErrors, totalErrorCount,notificationsAnchorEl, handleNotificationsClick, handleNotificationsClose, quoteType, filterAnchorEl, handleFilterClick, handleFilterClose, createdDateFilterValue, endCreatedDateFilterValue, handleCreatedDateFilterChange, handleEndCreatedDateFilterChange, handleResetFilterActionClick,  handleDoneFilterActionClick, quoteFilterValue, handleQuoteFilterChange, quoteOptions, spotDateFilterValue, endSpotDateFilterValue, handleSpotDateFilterChange, handleEndSpotDateFilterChange, movementFilterValue, handleMovementFilterChange, movementOptions, handleDownloadFileClick, downloadingQuotes} = props;

  var notificationsCount = totalErrorCount;
  
  const isAcceptable = isAcceptableQuote(quoteSelected);

  return (
    <Toolbar
      className={classNames(classes.root)}
    >
      <div className={classes.title}>
        <Typography variant="title" id="tableTitle">
          Quotes
        </Typography>
      </div>
      <div className={classes.spacer} />
      {quoteSelected != null && 
        <div className={classes.actions}>
          {isAcceptable &&
          <Button variant="contained" color="primary" className={classes.acceptButton} onClick={event => handleAcceptQuoteClick(event)}>
            Accept
        </Button>}
        {quoteType === 'pending' &&
        <Button variant="contained" color="secondary" className={classes.button} onClick={event => handleRejectQuoteClick(event)}>
            Reject
      </Button>}
        </div>}
      <Tooltip title="Filter list">
        <IconButton aria-label="Filter list" onClick={handleFilterClick}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id="simple-popper"
        open={Boolean(filterAnchorEl)}
        anchorEl={filterAnchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Card className={classes.card}>
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Typography variant="subheading" color="textSecondary">
                Filter Quotes
                </Typography>
              <div className={classes.controls}>
                  <TextField
                    id="date"
                    label="Start Created Date"
                    type="date"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={createdDateFilterValue}
                    onChange={event => handleCreatedDateFilterChange(event)}
                  />
                  <TextField
                    id="end-date"
                    label="End Created Date"
                    type="date"
                    className={classes.textFieldRight}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={endCreatedDateFilterValue}
                    onChange={event => handleEndCreatedDateFilterChange(event)}
                  />
              </div>
              <div className={classes.controls}>
                  <TextField
                    id="spot-date"
                    label="Start Movement Spot Date"
                    type="date"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={spotDateFilterValue}
                    onChange={event => handleSpotDateFilterChange(event)}
                  />
                  <TextField
                    id="end-spot-date"
                    label="End Movement Spot Date"
                    type="date"
                    className={classes.textFieldRight}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={endSpotDateFilterValue}
                    onChange={event => handleEndSpotDateFilterChange(event)}
                  />
              </div>
              <div className={classes.controls}>
                <FormControl required className={classes.formControl}>
                  <Select
                    value={quoteFilterValue}
                    onChange={handleQuoteFilterChange}
                    isClearable='true'
                    name="quote-filter"
                    options={quoteOptions}
                    placeholder='Quote ID'
                    inputProps={{
                      id: 'quote-filter',
                    }}
                  >
                    {
                      quoteOptions !== undefined && quoteOptions.map(quoteOption => {
                        return (
                          <MenuItem key={quoteOption.value} value={quoteOption.value}>{quoteOption.label}</MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>

              </div>
              <div className={classes.controls}>
                <FormControl required className={classes.formControl}>
                  <Select
                    value={movementFilterValue}
                    onChange={handleMovementFilterChange}
                    isClearable='true'
                    name="movement-filter"
                    options={movementOptions}
                    placeholder='Movement ID'
                    inputProps={{
                      id: 'movement-filter',
                    }}
                  >
                    {
                      movementOptions.map(movementOption => {
                        return (
                          <MenuItem key={movementOption.value} value={movementOption.value}>{movementOption.label}</MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
              </div>
              <div className={classes.controls}>
                <Button variant="contained"
                  className={classes.button}
                  onClick={event => handleResetFilterActionClick(event)}
                >
                  Reset
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={event => handleDoneFilterActionClick(event)}
                >
                  DONE
                </Button>
              </div>
            </CardContent>
          </div>
        </Card>
      </Popover>
      <Tooltip title="Download Quotes">
        <IconButton
          color={"inherit"}
          onClick={event => handleDownloadFileClick(event)}
          disabled={downloadingQuotes}
        >
          <CloudDownloadIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Notifications">
        <IconButton aria-label="Notifications" onClick={handleNotificationsClick}>
          {notificationsCount > 0 &&
          <Badge badgeContent={notificationsCount} color="secondary">
            <NotificationsActive />
          </Badge>}
          {notificationsCount === 0 &&
            <Notifications />}
        </IconButton>
      </Tooltip>
      <Popover
          id="popper-notification"
          open={Boolean(notificationsAnchorEl)}
          anchorEl={notificationsAnchorEl}
          onClose={handleNotificationsClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
        <MenuList>
        { hasQuoteErrors && 
          <MenuItem component={Link} to={'/quotes/errors/'}>Quote Errors</MenuItem>}
        { hasTripErrors &&
          <MenuItem component={Link} to={'/charters/errors/'}>Charter Errors</MenuItem>}
        </MenuList>
        
      </Popover>
    </Toolbar>
  );
};

QuoteListToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

QuoteListToolbar = withStyles(toolbarStyles)(QuoteListToolbar);


const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  
  tableWrapper: {
    overflowX: 'auto',
    width:'100%',
  },
  movementsTable: {
    border: '1px solid rgba(224, 224, 224, 1)'
  },
  margin: {
    margin: theme.spacing.unit,
  },
  textField: {
    flexBasis: 200,
  }, 
  head: {
    backgroundColor: '#4caf50'
  },
  spacer: {
    flex: '.96 1 10%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  detailCloseBtn: {
    margin: theme.spacing.unit,
    width: '24px',
    align: 'right',
    display: 'inline',
    paddingRight:'80px'
  },
  outdatedMoveStyle: {
    backgroundColor: 'rgb(255,87,34,0.5) !important'
  },
  detailRowStyle: {
    backgroundColor: '#f0f0f0'
  },
  hoverIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  pricingTableCellHeader: {
    textAlign: "right",
    padding: "4px 12px 4px 12px"
  },
  pricingTableCell: {
    textAlign: "right",
    padding: "4px 6px 4px 6px"
  },
  pricingTableTextCell: {
    textAlign: "left",
    padding: "4px 6px 4px 6px"
  },
  pricingTableCenterTextCell: {
    textAlign: "center",
    padding: "4px 6px 4px 6px"
  },
  pricingDetailTitle:{
    paddingLeft:"12px",
    fontSize: "1rem"
  },
  drawer: {
    flexShrink: 1,
    left:"0",
    right:"0",
    maxHeight:"50%"
  },
  drawerPaper: {
    left:"0",
    right:"0",
    maxHeight:"50%",
    backgroundColor: '#e4e4e4'
  },
  titleContainer: {
    width:'100%'
  },
  closeButtonContainer: {
    padding:"20px",
    textAlign:"right",
    display:"inline"
  }
}
);

class QuoteTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      order: 'asc',
      orderBy: 'date_created',
      page: 0,
      rowsPerPage: 10,
      quoteSelected: null,
      movementSelected: null,
      notificationsAnchorEl: null,
      acceptedAmount: 0,
      rejectedAmount: 0,
      showRejectionInvalid: false,
      openDialog: false,
      openReco: false,
      openDiscount: false,
      editMode : false, 
      rejectMode: false,
      quotes : this.props.quotes,
      quoteType: this.props.quoteType,
      openPriceDialog : false, 
      setOpenPriceDialog: false,
      pricingData : null,
      pricingDataLoading: false,
      breakApart: false,
      filterAnchorEl: null,
      createdDateFilterValue: '',
      endCreatedDateFilterValue: '',
      quoteFilterValue: 'None',
      spotDateFilterValue: '',
      endSpotDateFilterValue: '',
      movementFilterValue: 'None',
      downloadingQuotes: false
    };
  }

  handleFilterClick = event => {
    this.setState({ filterAnchorEl: event.currentTarget });
  };

  handleFilterClose = () => {
    this.setState({ filterAnchorEl: null });
  }

  handleResetFilterActionClick = (event) => {
    this.setState({ filterAnchorEl: null, createdDateFilterValue: '', endCreatedDateFilterValue: '', quoteFilterValue: 'None', spotDateFilterValue: '', endSpotDateFilterValue: '', movementFilterValue: 'None' });
  }

  handleDoneFilterActionClick = (event) => {
    this.setState({ filterAnchorEl: null });
  }

  handleCreatedDateFilterChange = event => {
    this.setState({ createdDateFilterValue: event.target.value })
  }

  handleEndCreatedDateFilterChange = event => {
    this.setState({ endCreatedDateFilterValue: event.target.value })
  }

  handleSpotDateFilterChange = event => {
    this.setState({ spotDateFilterValue: event.target.value })
  }

  handleEndSpotDateFilterChange = event => {
    this.setState({ endSpotDateFilterValue: event.target.value })
  }

  handleMovementFilterChange = movementFilter => {
    if (movementFilter!=null){
    this.setState({ movementFilterValue: movementFilter })
    } else {
      this.setState({movementFilterValue: 'None'});
    }
  }

  handleQuoteFilterChange = quoteFilter => {
      if (quoteFilter!=null){
      this.setState({ quoteFilterValue: quoteFilter })
    } else {
      this.setState({quoteFilterValue: 'None'});
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleQuoteClick = (event, quote) => {
    this.setState({ quoteSelected: quote });
  };

  handleMovementClick = (event, m) => {
    this.setState({ movementSelected: m })
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleAcceptQuoteClick = event => {
    this.setState({ openDialog: true, editMode: false, breakApart:false, openPriceDialog:false });
    
  }

  handleRejectQuoteClick = event => {
    this.setState({ openDialog: true, editMode: false, rejectMode: true });
  }
  
  handleDownloadFileClick = event => {
    this.setState({downloadingQuotes : true, pricingDataLoading: true});

    const quote_type = this.state.quoteType;
    const downloadUrl = EnvConfig.getConfig().apiUrl + "/csv-download/?quote_type=" + quote_type;

    console.log(downloadUrl);

    fetch(
      downloadUrl,
      {
        method: "GET",
        responseType: "blob",
        headers: {
          Authorization: 'Token ' + cookies.get('token'),
        },
      }
    )
    .then(function (response) {
      if (response.ok === false && response.status === 401) {
        window.location = "/login";
      }
      return response;
    })
    .then(async function (response) {
      var data = await response.blob();
      var currentDate = Moment().format("YYYY_MM_DD_HH_MM_SS");
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        quote_type + "_quotes_" + currentDate + ".csv"
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
      this.setState({downloadingQuotes : false, pricingDataLoading: false});
    })
    .catch((er) => {
      this.setState({downloadingQuotes : false, pricingDataLoading: false});
    });
  }

  handleAmountChange = event => {
    this.setState({ acceptedAmount: event.target.value });
  };

  handleRejectedAmountChange = event => {
      this.setState({ rejectedAmount: event.target.value });
  };

  handleDialogClose = () => {
    this.setState({ openDialog: false, rejectMode: false, showRejectionInvalid:false });
  };

  handleDialogSubmit = () => {
    let breakApart = this.state.breakApart;
    if (this.state.rejectMode) {
      
      if (this.isValidRejectAmount(this.state.rejectedAmount)){
        this.sendQuoteData(this.state.quoteSelected.id, 'rejected', null, this.state.rejectedAmount)
        this.removeSelectedQuote();
      } else {
        this.setState({ showRejectionInvalid : true} )
      }
    
    } else {
      this.sendQuoteData(this.state.quoteSelected.id, 'accepted', this.state.acceptedAmount, null)
      this.removeSelectedQuote();
    }
  }

  isValidRejectAmount = (amount) => {
    let validRejection = (amount>0);
    return validRejection
  }

  handleUnselectQuoteClick = (event, quote) =>{
    event.stopPropagation();
    this.setState({ quoteSelected: null });
  }
  
  removeSelectedQuote = () =>{
    var data = [...this.state.quotes];
    var index = data.map(function (quote) { return quote.id; }).indexOf(this.state.quoteSelected.id);
    data.splice(index, 1);
    this.setState({ quoteSelected: null, quotes: data, openDialog : false, acceptedAmount: 0, rejectedAmount: 0, rejectMode: false });
  }

  isSelected = id => this.state.quoteSelected != null ? this.state.quoteSelected.id === id : false;

  isMovementSelected = id => this.state.movementSelected != null ? this.state.movementSelected.movement_id === id : false;
  handleModalClose = () => {
    this.setState({ openReco: false });
  };

  loadedPricingData = {};
  handleRecoPriceClick = (event, q) => {

    let priced_detail_id = q.id;
    if (priced_detail_id){
      if (!this.loadedPricingData[q.id]){
      this.setState({ pricingDataLoading: true, pricingDetailQuote: q, pricingData: null,  openPriceDialog: true });
      fetch(envConfig.config.apiUrl + '/api/pricing_trip_data/?quote_id='+priced_detail_id, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + cookies.get('token')
        },
      })
      .then(response => response.json())
      .then(data => {
        this.loadedPricingData[q.id] = data.results
        this.setState({ pricingData: data.results, pricingDetailQuote: q,pricingDataLoading: false, openPriceDialog: true, openDialog:false  });
      })
      } else {
        this.setState({pricingData: this.loadedPricingData[q.id],pricingDataLoading: false, pricingDetailQuote: q, openPriceDialog: true, openDialog:false  });
      }
    } 
  }

  handleRecoDiscountClick = event => {
    this.setState({ openDiscount: true, openPriceDialog: false, openDialog:false })
  }

  handleDiscountClose = event => {
    this.setState({ openDiscount: false })
  }

  sendQuoteData = (quote, status, acceptedAmount, rejectedAmount ) => {    
    let payloadData = {
      quote_id: quote.quote_id,
      status: status,
      accepted_price: acceptedAmount,
      rejected_price: rejectedAmount,
    }
    if (this.state.breakApart===true){
      payloadData.vehicle_to_stay = false;
    }

    fetch(envConfig.config.apiUrl + '/api/quotes/' + quote + '/', {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + cookies.get('token')
      },
      body: JSON.stringify(payloadData)
    })
  }

  handleNotificationsClick = event => {
    if(this.props.hasErrors){
      this.setState({ notificationsAnchorEl: event.currentTarget });
    }
  };

  handleNotificationsClose = () => {
    this.setState({ notificationsAnchorEl: null });
  };
  handleBreakApartChange = event => {
    event.target.value = (event.target.value=='false') ? 'true' : 'false';
    if (event.target.value =='true'){
      this.setState({breakApart:true})
    } else {
      this.setState({breakApart:false})
    }

  }
  getJoinedStringFromArray = function(fieldValue, delimiter="|"){
    let joinedString = "-"
    try{
      let items = JSON.parse(fieldValue)
      items = items.replace(/'/g, '"');
      items = JSON.parse(items)
      joinedString = items.join(delimiter);
    }
    catch {}
    return joinedString
  }
  getSelectedId = () =>{
    //alert(this.state.quoteSelected.id);
    return this.state.quoteSelected.id;
  }
  isInPricingSuperUserGroup = () => {
    let isInSuperUser = this.props.user != null && this.props.user.groups.find(g => { return g.name == 'pricing_super_user' })
    return (isInSuperUser) ? true : false;
  }
  isInPricingDebugUserGroup = () => {
    let isInDebugUser = this.props.user != null && this.props.user.groups.find(g => { return g.name == 'pricing_debug' })
    return (isInDebugUser) ? true : false;
  }
  
  getQuoteDiscount = (q) => {
    if (q != null){
      var quoteDiscount = 0;
      q.movements.forEach(m => {
        if (parseFloat(m.discount_btns)>0) {
          quoteDiscount+=parseFloat(m.discount_btns)
        };
      })
      return Math.round(quoteDiscount);
    }
  }
  getQuoteVehiclesCell = (q) => {
    let returnJsx = <React.Fragment></React.Fragment>
    
    if (this.isInPricingSuperUserGroup() || this.isInPricingDebugUserGroup()){
      returnJsx = <TableCell component="th" scope="row" padding="dense">{q.vehicle_count}</TableCell>
    }
    return returnJsx;
  }
  getTripTypeIcons = (quote_movements, classes) => {
    let hasRoundtripMovements = false;
    let hasSingJourneyMovements = false;
    //technically can have both
    quote_movements.forEach(m => {
      if (m.single_journey) {
        hasSingJourneyMovements = true;
      } else {
        hasRoundtripMovements = true;
      };
    })
    
    let rtIndicator = <Badge badgeContent="" className={classes.hoverIcon}><Tooltip placement="left" title={'Quote Contains Contains Round Trip Movement(s)'}><RepeatIcon style={{ fontSize: 18 }} color="primary"/></Tooltip></Badge>
    
    let singleJourney = <Badge badgeContent="" className={classes.hoverIcon}><Tooltip placement="left" title={'Quote Contains Single Journey Movement(s)'}><TrendingFlatIcon style={{ fontSize: 18 }} color="primary"/></Tooltip></Badge>

    let returnObject = <React.Fragment>{hasRoundtripMovements && rtIndicator}{ hasSingJourneyMovements && singleJourney }</React.Fragment>
    return returnObject

  }

  getPricingDetailHeader(isPricingSuperUser, isPricingDebugUser, classes){
    
    let rrBetaDetailHeader =  <TableRow>
      <TableCell className={classes.pricingTableCellHeader}>Bus Capacity Type</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Customer Classification</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Engine Id</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Recommended Price</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Discount BTNS</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Probability of Acceptance</TableCell>
      </TableRow>;
    
    //Super User detail view
    let suDetailHeader =  <TableRow>
      <TableCell className={classes.pricingTableCellHeader}>Bus Capacity Type</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Customer Classification</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Engine Id</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Direct Cost</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Fixed Cost</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Boost Cost</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Minimum Price</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Standard Price</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Recommended Price</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Discount BTNS</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Probability of Acceptance</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Num Periods</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Expected Demand Per Period</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Markup Per Period</TableCell>
      </TableRow>;

    //Super User detail view
    let debugDetailHeader =  <TableRow className={classes.pricingTableCellHeader}>
      <TableCell className={classes.pricingTableCellHeader}>Bus Capacity Type</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Customer Classification</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Engine Id</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Direct Cost</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Fixed Cost</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Fixed Cost Adj</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Boost Cost</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Full Cost Engine</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Minimum Price</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Standard Price</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Recommended Price</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Discount BTNS</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Probability of Acceptance</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Num Periods Adj</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Num Periods</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Expected Demand Per Period</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Markup Per Period</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Expected New Requests Per Period</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Expected Demand Per Period</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Expected Capacity Utilization Per Period</TableCell>
      <TableCell className={classes.pricingTableCellHeader}>Expected Farmout Per Period</TableCell>
      </TableRow>;

      let headerJsx = rrBetaDetailHeader;
      if (isPricingSuperUser){
        headerJsx = suDetailHeader;
      }
      if (isPricingDebugUser){
        headerJsx = debugDetailHeader;
      }
      return headerJsx
  }
  getPricingDetailContent(show_prices, isPricingSuperUser, isPricingDebugUser, pricingData, classes){
    let rrBetaDetailContent = show_prices && pricingData != null && 
      pricingData.map(m => {
          return (
            <TableRow
              tabIndex={-1}
              key={m.id}
              className={classes.detailRowStyle}>
              <TableCell className={classes.pricingTableCellHeader}>{m.bus_capacity_type}</TableCell>
              <TableCell className={classes.pricingTableCellHeader}>{m.customer_classification}</TableCell>
              <TableCell className={classes.pricingTableCellHeader}>{m.trip_id}</TableCell>
              <TableCell className={classes.pricingTableCellHeader}>{Math.round(m.recommended_price)}</TableCell>
              <TableCell className={classes.pricingTableCellHeader}>{Math.round(m.discount_btns)}</TableCell>
              <TableCell className={classes.pricingTableCellHeader}>{parseFloat(m.probability_acceptance).toFixed(2)}</TableCell>
              </TableRow>
          );
        })
    

    let suDetailContent = show_prices && pricingData != null && isPricingSuperUser && 
      pricingData.map(m => {
          return (
            <TableRow
              tabIndex={-1}
              key={m.id}
              className={classes.detailRowStyle}>
              <TableCell className={classes.pricingTableCellHeader}>{m.bus_capacity_type}</TableCell>
              <TableCell className={classes.pricingTableCellHeader}>{m.customer_classification}</TableCell>
              <TableCell className={classes.pricingTableCellHeader}>{m.trip_id}</TableCell>
              <TableCell className={classes.pricingTableCellHeader}>{Math.round(m.direct_cost)}</TableCell>
              <TableCell className={classes.pricingTableCellHeader}>{Math.round(m.fixed_cost)}</TableCell>
              <TableCell className={classes.pricingTableCellHeader}>{Math.round(m.boost_cost)}</TableCell>       
              <TableCell className={classes.pricingTableCellHeader}>{Math.round(m.minimum_price)}</TableCell>
              <TableCell className={classes.pricingTableCellHeader}>{Math.round(this.state.pricingDetailQuote.standard_price)}</TableCell>
              <TableCell className={classes.pricingTableCellHeader}>{Math.round(m.recommended_price)}</TableCell>
              <TableCell className={classes.pricingTableCellHeader}>{Math.round(m.discount_btns)}</TableCell>
              <TableCell className={classes.pricingTableCellHeader}>{parseFloat(m.probability_acceptance).toFixed(2)}</TableCell>
              <TableCell className={classes.pricingTableCellHeader}>{m.num_periods}</TableCell>
              <TableCell className={classes.pricingTableCellHeader}>{this.getJoinedStringFromArray(m.expected_demand_classification_per_period)}</TableCell>
              <TableCell className={classes.pricingTableCellHeader}>{this.getJoinedStringFromArray(m.markup_per_period)}</TableCell>
              </TableRow>
          );
        })
      let debugDetailContent = show_prices && pricingData != null && isPricingSuperUser && 
        pricingData.map(m => {
            return (
              <TableRow
                tabIndex={-1}
                key={m.id}
                className={classes.detailRowStyle}>
                <TableCell className={classes.pricingTableCellHeader}>{m.bus_capacity_type}</TableCell>
                <TableCell>{m.customer_classification}</TableCell>
                <TableCell className={classes.pricingTableCellHeader}>{m.trip_id}</TableCell>
                <TableCell className={classes.pricingTableCellHeader}>{Math.round(m.direct_cost)}</TableCell>
                <TableCell className={classes.pricingTableCellHeader}>{Math.round(m.fixed_cost)}</TableCell>
                <TableCell className={classes.pricingTableCellHeader}>{Math.round(m.fixed_cost_adj)}</TableCell>
                <TableCell className={classes.pricingTableCellHeader}>{Math.round(m.boost_cost)}</TableCell> 
                <TableCell className={classes.pricingTableCellHeader}>{Math.round(m.full_cost_engine)}</TableCell>
                <TableCell className={classes.pricingTableCellHeader}>{Math.round(m.minimum_price)}</TableCell>
                <TableCell className={classes.pricingTableCellHeader}>{Math.round(this.state.pricingDetailQuote.standard_price)}</TableCell>
                <TableCell className={classes.pricingTableCellHeader}>{Math.round(m.recommended_price)}</TableCell>
                <TableCell className={classes.pricingTableCellHeader}>{Math.round(m.discount_btns)}</TableCell>
                <TableCell className={classes.pricingTableCellHeader}>{parseFloat(m.probability_acceptance).toFixed(2)}</TableCell>
                <TableCell className={classes.pricingTableCellHeader}>{parseFloat(m.num_periods_adj).toFixed(2)}</TableCell>
                <TableCell className={classes.pricingTableCellHeader}>{m.num_periods}</TableCell>
                <TableCell className={classes.pricingTableCellHeader}>{this.getJoinedStringFromArray(m.expected_demand_classification_per_period)}</TableCell>
                <TableCell className={classes.pricingTableCellHeader}>{this.getJoinedStringFromArray(m.markup_per_period)}</TableCell>
                <TableCell className={classes.pricingTableCellHeader}>{this.getJoinedStringFromArray(m.expected_new_requests_per_period)}</TableCell>
                <TableCell className={classes.pricingTableCellHeader}>{this.getJoinedStringFromArray(m.expected_demand_per_period)}</TableCell>
                <TableCell className={classes.pricingTableCellHeader}>{this.getJoinedStringFromArray(m.expected_capacity_utilization_per_period)}</TableCell>
                <TableCell className={classes.pricingTableCellHeader}>{this.getJoinedStringFromArray(m.expected_farm_out_per_period)}</TableCell>
                </TableRow>
            );
          })
        let detailContentJsx = rrBetaDetailContent;
        if (isPricingSuperUser){
          detailContentJsx = suDetailContent;
        }
        if (isPricingDebugUser){
          detailContentJsx = debugDetailContent;
        }
        //TODO: add debug headerl
        return detailContentJsx
  }

  getMinPrice = (n) => {
    let min_price = "-";
    if (n.minimum_cost > 0) {
      min_price = "$"+Math.round(parseFloat(n.minimum_cost));
    }

    if (parseFloat(n.min_assignment_price) > 0 && parseFloat(n.min_assignment_price) < parseFloat(n.recommended_price)) {
      min_price = "$"+Math.round(parseFloat(n.min_assignment_price));
    }
    return min_price;
  }

  getMinimumPriceFieldForUser = (quoteObject, classes) => {
    if (this.isInPricingSuperUserGroup() || this.isInPricingDebugUserGroup()){
      let returnJsx = <TableCell className={classes.pricingTableCell} component="th" scope="row">{this.getMinPrice(quoteObject)}</TableCell>;
      return returnJsx
    }
  }
  getStandardPriceFieldForUser = (quoteObject, classes) => {
    if (this.isInPricingSuperUserGroup() || this.isInPricingDebugUserGroup()){
      let returnJsx = <TableCell className={classes.pricingTableCell} component="th" scope="row">{"$"+Math.round(quoteObject.standard_price)}</TableCell>;
      return returnJsx
    }
  }
  getFinalPriceFieldForUser = (quoteObject, quoteType, classes) => {
    let returnJsx = <React.Fragment></React.Fragment>;
    if (quoteType != 'pending'){
      if ((this.isInPricingSuperUserGroup() || this.isInPricingDebugUserGroup())){
        if(quoteObject.final_price != ""){
          returnJsx = <TableCell className={classes.pricingTableCell} component="th" scope="row">${quoteObject.final_price}</TableCell>;
        }
        else{
          returnJsx = <TableCell className={classes.pricingTableCell} component="th" scope="row"></TableCell>;
        }
      }
    }
    return returnJsx
  }
  
  getUserQuoteHeaderRows = (quoteType) => {

    let table_head_rows = [];

    table_head_rows.push({ id: 'date_created', numeric: false, disablePadding: false, label: 'Created' });

    if (quoteType=="accepted") {
      let quoteTypeDisplay = quoteType.charAt(0).toUpperCase() + quoteType.slice(1) + ' Date';
      table_head_rows.push({ id: 'decision_datetime', numeric: false, disablePadding: false, label: quoteTypeDisplay })
    }

    table_head_rows.push({ id: 'id', numeric: false, disablePadding: true, label: 'Quote ID' });
    table_head_rows.push({ id: 'company_name', numeric: false, disablePadding: false, customStyle: companyNameColumnHeader, label: 'Company Name' });
    table_head_rows.push({ id: 'client_first_name', numeric: false, disablePadding: false, customStyle: textColumnHeader, label: 'Customer Name' });
    table_head_rows.push({ id: 'phone', numeric: false, disablePadding: false, label: 'Phone', customStyle: phoneHeader });

    if (this.isInPricingSuperUserGroup() || this.isInPricingDebugUserGroup()){
      table_head_rows.push({ id: 'minimum_price', numeric: true, disablePadding: false,customStyle: pricingTableCellHeader, label: 'Min Price' });
      table_head_rows.push({ id: 'standard_price', numeric: true, disablePadding: false, customStyle: pricingTableCellHeader, label: 'Standard Price' });
      table_head_rows.push({ id: 'vehicle_count', numeric: false, disablePadding: true, customStyle: textColumnHeader, label: 'Vehicles' });
    }

    table_head_rows.push({ id: 'icon_placeholder', numeric: false, textAlign: 'center', customStyle: pricingTableInfoHeader, disablePadding: true, label: 'Info' });
    table_head_rows.push({ id: 'recommended_price', numeric: true, disablePadding: false, customStyle: pricingTableCellHeader, label: 'Recom. Price'});
    table_head_rows.push({ id: 'operator_id', numeric: false, disablePadding: true, customStyle: phoneHeader, label: 'Operator' });

    if (quoteType!="pending") {
      table_head_rows.push({ id: 'final_price', numeric: true, disablePadding: true, customStyle: pricingTableCellHeader, label: 'Final Price' });
    }
    table_head_rows.push({ id: 'expandable_icon', numeric: false, disablePadding: true, label: '' });

    return table_head_rows;
  }
  handlePriceDialogClose = () => {
    this.setState({ openPriceDialog: false, pricingData:null });
  };

  filter = (quotes) => {

    var newQuotes = quotes.slice();

    if (this.state.movementFilterValue !== 'None') {
      newQuotes = newQuotes.filter(quote => {
        const movementsFound = quote.movements.filter(m => {
          return m.movement_id === this.state.movementFilterValue.label;
        });
        return movementsFound.length > 0;
      });
    }

    if (this.state.quoteFilterValue !== 'None') {
      newQuotes = newQuotes.filter(quote => {
        return quote.quote_id == this.state.quoteFilterValue.value;
      });
    }

    if (this.state.createdDateFilterValue !== '') {
      newQuotes = newQuotes.filter(quote => {
        const createdDate = Moment(quote.date_created, 'MM-DD-YYYY HH:mm:ss');
        const customDate = Moment(this.state.createdDateFilterValue, 'YYYY-MM-DD');
        return customDate.isSameOrBefore(createdDate, 'day');
      });
    }

    if (this.state.endCreatedDateFilterValue !== '') {
      newQuotes = newQuotes.filter(quote => {
        const createdDate = Moment(quote.date_created, 'MM-DD-YYYY HH:mm:ss');
        const customDate = Moment(this.state.endCreatedDateFilterValue, 'YYYY-MM-DD');
        return customDate.isSameOrAfter(createdDate, 'day');
      });
    }

    if (this.state.spotDateFilterValue !== '') {
      newQuotes = newQuotes.filter(quote => {
        const movementsFound = quote.movements.filter(m => {
          const spotDate = Moment(m.spot_datetime, 'MM-DD-YYYY HH:mm:ss');
          const customDate = Moment(this.state.spotDateFilterValue, 'YYYY-MM-DD');
          return customDate.isSameOrBefore(spotDate, 'day');
        });
        return movementsFound.length > 0;
      });
    }

    if (this.state.endSpotDateFilterValue !== '') {
      newQuotes = newQuotes.filter(quote => {
        const movementsFound = quote.movements.filter(m => {
          const spotDate = Moment(m.spot_datetime, 'MM-DD-YYYY HH:mm:ss');
          const customDate = Moment(this.state.endSpotDateFilterValue, 'YYYY-MM-DD');
          return customDate.isSameOrAfter(spotDate, 'day');
        });
        return movementsFound.length > 0;
      });
    }

    return newQuotes;
  }

  render() {
    const { classes, hasErrors, hasQuoteErrors, hasTripErrors,quoteType, handleQuoteType,totalErrorCount, quoteOptions, movementOptions } = this.props;
    const { order, orderBy, rowsPerPage, page, quoteSelected, editMode, rejectMode, notificationsAnchorEl, openDiscount, pricingData, showRejectionInvalid, filterAnchorEl, createdDateFilterValue, endCreatedDateFilterValue, quoteFilterValue, spotDateFilterValue, endSpotDateFilterValue, movementFilterValue, downloadingQuotes} = this.state;
    const { handleAcceptQuoteClick, handleDiscountClose, handleRejectQuoteClick, handleNotificationsClick, handleNotificationsClose, handlePriceDialogClose, handleFilterClick, handleFilterClose, handleResetFilterActionClick, handleDoneFilterActionClick, handleCreatedDateFilterChange, handleEndCreatedDateFilterChange, handleQuoteFilterChange, handleSpotDateFilterChange, handleEndSpotDateFilterChange, handleMovementFilterChange, handleDownloadFileClick} = this

    const isPricingSuperUser = this.isInPricingSuperUserGroup()
    const isPricingDebugUser = this.isInPricingDebugUserGroup()
    //regular RR user view
    let detailHeader = "";
    let detailContent = "";
    
    detailHeader = this.getPricingDetailHeader(isPricingSuperUser,isPricingDebugUser,classes);
    detailContent = this.getPricingDetailContent(true , isPricingSuperUser,isPricingDebugUser,pricingData, classes);

    const filteredQuotes = this.filter(this.state.quotes);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredQuotes.length - page * rowsPerPage);

    return (
      <React.Fragment>
        <Paper className={classes.root} id='paper-container'>
          <QuoteListToolbar quoteSelected={quoteSelected} hasErrors={hasErrors} hasTripErrors={hasTripErrors} hasQuoteErrors={hasQuoteErrors} handleAcceptQuoteClick={handleAcceptQuoteClick}  totalErrorCount={totalErrorCount} handleRejectQuoteClick={handleRejectQuoteClick} handleNotificationsClick={handleNotificationsClick} notificationsAnchorEl={notificationsAnchorEl} handleNotificationsClose={handleNotificationsClose} handleQuoteType={handleQuoteType} quoteType={quoteType} filterAnchorEl={filterAnchorEl} handleFilterClick={handleFilterClick} handleFilterClose={handleFilterClose}   handleResetFilterActionClick={handleResetFilterActionClick} handleDoneFilterActionClick={handleDoneFilterActionClick} handleCreatedDateFilterChange={handleCreatedDateFilterChange} handleEndCreatedDateFilterChange={handleEndCreatedDateFilterChange} handleSpotDateFilterChange={handleSpotDateFilterChange} handleEndSpotDateFilterChange={handleEndSpotDateFilterChange} createdDateFilterValue={createdDateFilterValue} endCreatedDateFilterValue={endCreatedDateFilterValue} quoteFilterValue={quoteFilterValue} quoteOptions={quoteOptions} handleQuoteFilterChange={handleQuoteFilterChange} spotDateFilterValue={spotDateFilterValue} endSpotDateFilterValue={endSpotDateFilterValue} movementFilterValue={movementFilterValue} handleMovementFilterChange={handleMovementFilterChange} movementOptions={movementOptions} handleDownloadFileClick={handleDownloadFileClick} downloadingQuotes={downloadingQuotes}>
          </QuoteListToolbar> 
          <div className={classes.toggleContainer}>
              <ToggleButtonGroup value={quoteType} exclusive onChange={this.props.handleQuoteType} >
                <ToggleButton value='pending' className={classes.toggleButton}>
                  Pending
                </ToggleButton>
                <ToggleButton value="rejected" className={classes.toggleButton}>
                  Rejected
                </ToggleButton>
                <ToggleButton value="accepted" className={classes.toggleButton}>
                  Accepted
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          <div className={classes.tableWrapper}>
         
            <Table className={classes.table} aria-labelledby="tableTitle">
              <SortTableHead 
                quoteType={quoteType}
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
                rowCount={filteredQuotes.length}
                rows={this.getUserQuoteHeaderRows(quoteType)}
              />
              <TableBody>
                {sortingHelper.stableSort(filteredQuotes, sortingHelper.getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(n => {
                    const isSelected = this.isSelected(n.id);
                    return (
                      <React.Fragment key={n.id}>
                        <TableRow
                          hover
                          onClick={event => this.handleQuoteClick(event, n)}
                          role="checkbox"
                          aria-checked={isSelected}
                          tabIndex={-1}
                          key={n.quote_id}
                          selected={isSelected}
                        >
                          <TableCell component="th" scope="row" padding="dense">
                            <ReactMoment parse="MM-DD-YYYY HH:mm:ss" format="MM/DD/YYYY HH:mm">
                              {n.date_created}
                            </ReactMoment>
                          </TableCell>
                          { quoteType == 'accepted' && 
                          <TableCell component="th" scope="row" padding="dense">
                            {n.decision_datetime != null &&
                            <ReactMoment parse="MM-DD-YYYY HH:mm:ss" format="MM/DD/YYYY HH:mm">
                              {n.decision_datetime}
                            </ReactMoment>}
                          </TableCell>}
                          <TableCell component="th" className={classes.pricingTableTextCell} scope="row" padding="none">{n.quote_id}</TableCell>
                          <TableCell component="th" className={classes.pricingTableTextCell} scope="row" padding="none">{n.company_name}</TableCell>
                          <TableCell component="th" scope="row" className={classes.pricingTableTextCell} padding="none">{n.client_first_name} {n.client_last_name}</TableCell>
                          <TableCell component="th" scope="row" padding="none">{n.phone}</TableCell>
                          {this.getMinimumPriceFieldForUser(n, classes)}
                          {this.getStandardPriceFieldForUser(n, classes)}
                          {this.getQuoteVehiclesCell(n)}
                          <TableCell style={{ textAlign: "center", minWidth:"60px" }}  component="th" scope="row" padding="none">{this.getTripTypeIcons(n.movements, classes)}{n.recommended_price > 0 &&  this.getQuoteDiscount(n)>0 &&
            <Badge badgeContent="" className={classes.hoverIcon} onClick={this.handleRecoDiscountClick} discount={this.getQuoteDiscount(n)} quote_id={n.id}>         <Tooltip placement="left" title={'Recommended Discount $'+this.getQuoteDiscount(n)}><MonetizationOnIcon style={{ fontSize: 18 }} color="primary"/></Tooltip></Badge>}
            {n.recommended_price > 0 &&
            <Badge badgeContent="" className={classes.hoverIcon} onClick={event => this.handleRecoPriceClick(event,n)} discount_amt={this.getQuoteDiscount(n).toString()} quote_id={n.id}><InfoIcon style={{ fontSize: 18 }} color="primary"/></Badge>}
            </TableCell>
                          <TableCell className={classes.pricingTableCell} component="th" scope="row">{(n.recommended_price == 0) && "N/A"}{n.recommended_price > 0 && "$" + Math.round(n.recommended_price)}</TableCell>
                          <TableCell className={classes.pricingTableCenterTextCell} component="th" scope="row" padding="none">{n.operator_id}</TableCell>
                          {this.getFinalPriceFieldForUser(n, quoteType, classes)}
                          <TableCell component="th" scope="row" padding="none" className={classes.expandButton}>
                            { !isSelected && 
                            <IconButton color="primary" className={classes.expandButton} aria-label="Expand Quote">
                              <ExpandMore color="primary"/> 
                            </IconButton>}
                            { isSelected && 
                            <IconButton color="primary" className={classes.expandButton} aria-label="Un-Expand Quote" onMouseDown={event => this.handleUnselectQuoteClick(event, n)}>
                              <ExpandLess color="primary"/> 
                            </IconButton>}
                          </TableCell>
                        </TableRow>
                        {isSelected && n.movements.length > 0 &&
                          <TableRow>
                            <TableCell colSpan={'100%'}>
                              <Table className={classes.movementsTable}>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Movement ID</TableCell>
                                    <TableCell>Spot Date</TableCell>
                                    <TableCell>Pickup</TableCell>
                                    <TableCell>Arrival Date</TableCell>
                                    <TableCell>Destination</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {
                                    sortingHelper.stableSort(n.movements, sortingHelper.getSorting('asc', 'spot_datetime'))
                                      .map(m => {
                                        const isMovementSelected = this.isMovementSelected(m.movement_id);
                                        return (
                                          <TableRow
                                            hover
                                            onClick={event => this.handleMovementClick(event, m)}
                                            role="checkbox"
                                            tabIndex={-1}
                                            selected={isMovementSelected}
                                            key={m.movement_id}
                                            className={m.is_outdated ? classes.outdatedMoveStyle : ''}>
                                            <TableCell>{m.movement_id}</TableCell>
                                            <TableCell>
                                              <ReactMoment parse="MM-DD-YYYY HH:mm:ss" format="MM/DD/YYYY HH:mm">
                                                {m.spot_datetime}
                                            </ReactMoment>
                                            </TableCell>
                                            <TableCell>{m.pickup_location_name}</TableCell>
                                            <TableCell>
                                              <ReactMoment parse="MM-DD-YYYY HH:mm:ss" format="MM/DD/YYYY HH:mm">
                                                {m.arrival_datetime}
                                              </ReactMoment>
                                            </TableCell>
                                            <TableCell>{m.destination_location_name}</TableCell>
                                          </TableRow>
                                        );
                                      })
                                  }
                                </TableBody>
                              </Table>
                            </TableCell>
                          </TableRow>
                        }
                      </React.Fragment>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={'100%'} ></TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            component="div"
            count={filteredQuotes.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={openDiscount}
            onClose={handleDiscountClose}
            aria-labelledby="draggable-dialog-title"
          >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
              Recommended Discount
            </DialogTitle>
            <DialogContent>
              This quote is currently a round trip with vehicle to stay required.
              <p>Recommended discount to offer if customer changes vehicle to stay requirement: ${this.getQuoteDiscount(this.state.quoteSelected)}</p>
              <p>A checkbox for changing this requirement is offered the accept dialog.</p>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleDiscountClose} color="primary">
                Close
              </Button>
            </DialogActions>
        </Dialog>

        <Drawer
            className={classes.drawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="persistent"
            open={this.state.openPriceDialog}
            ModalProps={{
              style: { right:'auto', left:'auto' }
            }}

            anchor="bottom"
          >
      { this.state.pricingDataLoading && <LinearProgress />}
      <Toolbar onClick={handlePriceDialogClose} disableGutters={true} className={(classes.titleContainer)}>
      <div className={classes.title}>
        <Typography variant="title" id="tableTitle" className={classes.pricingDetailTitle}>
          Pricing Detail : {this.state.pricingDetailQuote && this.state.pricingDetailQuote.quote_id } : { this.state.pricingDetailQuote && this.state.pricingDetailQuote.company_name }
        </Typography>
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
      <Button autoFocus className ={classes.detailCloseBtn} onClick={handlePriceDialogClose}>
                Close
      </Button>
      </div>
    </Toolbar>
        <Table className={classes.table}>
            <TableHead>
              { pricingData != null && detailHeader}
            </TableHead>
            <TableBody>
              { pricingData != null && detailContent } 
            </TableBody>
          </Table>
        </Drawer>
        <Dialog
          open={this.state.openDialog}
          onClose={this.handleDialogClose}
          aria-labelledby="form-dialog-title"
        >
          { editMode && <DialogTitle id="form-dialog-title">Edit Price</DialogTitle>} 
          { !editMode && <DialogTitle id="form-dialog-title">Confirmation</DialogTitle>}
          <DialogContent>
          { editMode && 
            <DialogContentText>
              Please enter the desired amount
            </DialogContentText>
          }
          { !editMode && !rejectMode &&
            <DialogContentText>
              Please confirm the final price
            </DialogContentText>
          }
          
          {!editMode && !rejectMode &&
            <TextField
              id="filled-adornment-amount"
              type="number"
              className={classNames(classes.margin, classes.textField)}
              variant="filled"
              label="Amount"
              value={this.state.acceptedAmount}
              onChange={event => this.handleAmountChange(event)}
              InputProps={{
                startAdornment: (
                  <InputAdornment variant="filled" position="start">$</InputAdornment>
                ),
              }}
            />}
            {this.getQuoteDiscount(this.state.quoteSelected)>0 && !editMode && !rejectMode && 
              <DialogContentText>
              <FormControlLabel
              control={
                <Checkbox 
                onChange={event => this.handleBreakApartChange(event)}
                value='false'
                inputProps={{ 'aria-label': 'Allow break apart round trip with discount' }}
              />
              }
              label = 'Break Apart with discount'
              />
              </DialogContentText>
          }
            { rejectMode && 
            <DialogContentText>
              Last Quoted Price
            </DialogContentText>
          }
          { rejectMode &&
            <TextField
              id="filled-adornment-amount"
              type="number"
              className={classNames(classes.margin, classes.textField)}
              variant="filled"
              label="Amount"
              value={this.state.rejectedAmount}
              onChange={event => this.handleRejectedAmountChange(event)}
              InputProps={{
                startAdornment: (
                  <InputAdornment variant="filled" position="start">$</InputAdornment>
                ),
              }}
            />}
            {rejectMode && showRejectionInvalid && 
            <DialogContentText>
            Enter a Valid Rejection Price
          </DialogContentText>
          }
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleDialogSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

QuoteTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(QuoteTable);
